import React from "react";
import {Button, Spinner} from "reactstrap";

function ButtonLoader({
    onClick,
    loading,
    text,
    color,
    className,
    disabled,

                      }) {
    return (
        <Button
            color={color} className={className + (!loading ? "" : " d-flex justify-content-center align-items-center py-2")} onClick={onClick} disabled={disabled}
            style={{height: !loading ? "auto" : 40}}
        >
            {!loading ? text : <Spinner  />}
        </Button>
    )
}

export default ButtonLoader;