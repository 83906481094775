import { initializeApp, getApps, getApp } from 'firebase/app';
import config from "../config/default.js"
import { getMessaging, getToken} from "firebase/messaging";

const firebaseApp = getApps().length === 0 ? initializeApp(config.firebase) : getApp();

//const messaging = initializedFirebaseApp.messaging();
const messaging = getMessaging(firebaseApp);
getToken(messaging, {vapidKey: "BFGfHJNShIZ2SOJhlIwl0T9ZW4n-V8gsDapI7uqyaZmaijRdI1Y5T8urbceM10fihyGNbtNKyoe2NbI93eqSJxc"})
  .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
  }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    // ...
  });

export { messaging };