import React, {createRef, useState} from "react";
import {Button, Card, CardBody, CardFooter, Col, FormGroup, Form, Input, Row} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import logo from "../assets/img/tv.svg";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import {Link, useHistory} from "react-router-dom";
import {addUser} from "../firebase/user";

function Register () {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [society, setSociety] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCounty] = useState("");
  const [postal, setPostal] = useState("");

  const notificationAlert = createRef();

    const history = useHistory();

  const validateForm = () => {
    return (email &&  password && society && country && city && address)
        ? (
            email.length > 0 &&
            society.length > 0 &&
            country.length > 0 &&
            city.length > 0 &&
            password.length > 8 &&
            address.length > 0 &&
            password === confirm
        )
        : false;
  }

  const submit = e => {
    e.preventDefault();
      addUser({
          lastname: society, firstname: "", email, password,
          city, country, postal,
          profil: "manager", society, notificationAlert, history
      });
  }


  return (
      <div>
        <div className="react-notification-alert-container">
            <NotificationAlert ref={notificationAlert} />
        </div>
        <Row>
            <Col xs={{size: "11", offset: "1"}} sm={{size: "10", offset: "1"}} lg={{size: "4", offset: "2"}} xl={{size: "3", offset: "1"}}>
              <div className="login-left-panel">
                  <div className="login-left-panel-title">
                        <img src={logo} alt="" />
                  </div>
                  <div className="login-left-panel-subtitle">
                    <h3>Bienvenue sur Kioo</h3>
                  </div>
                  <div className="login-left-panel-citation">
                    <p>Encouragez l’innovation. Le changement est notre force vitale,</p>
                    <p>la stagnation est notre glas. ~ David M. Ogilvy</p>
                  </div>
                </div>
            </Col>
            <Col xs={{size: "11", offset: "1"}} sm={{size: "10", offset: "1"}} lg="6">
              <Card className="card-login" >
                <CardHeader className="card-header">
                    <h1 className="">Inscription</h1>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={submit}>
                  <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Société</label>
                          <Input
                              id="society"
                              autoFocus
                              placeholder="Nom de la société"
                              type="text"
                              value={society}
                              onChange={(e) => setSociety(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Adresse mail</label>
                          <Input
                              id="email"
                              placeholder="Email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Mot de passe</label>
                          <Input
                              id="password"
                              placeholder="Mot de passe"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Confirmation</label>
                          <Input
                              id="confirm"
                              placeholder="Mot de passe"
                              type="password"
                              value={confirm}
                              onChange={(e) => setConfirm(e.target.value)}

                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Adresse</label>
                          <Input
                              id="address"
                              placeholder="Adresse"
                              type="text"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Code postal</label>
                          <Input
                              id="postal"
                              placeholder="0000"
                              type="text"
                              value={postal}
                              onChange={(e) => setPostal(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Pays</label>
                          <Input
                              id="country"
                              placeholder="Pays"
                              type="text"
                              value={country}
                              onChange={(e) => setCounty(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Ville</label>
                          <Input
                              id="city"
                              placeholder="Ville"
                              type="text"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button type="submit" color="primary" disabled={!validateForm()}>
                      Créer
                    </Button>
                  </Form>
                </CardBody>
                <CardFooter>
                <Row>
                  <Col>
                    <div className="button-container">
                      <Link className="" to="/">
                        Déja un compte?
                      </Link>
                    </div>
                  </Col>
                  <Col>
                    <div className="button-container">
                      <a
                            href="https://www.initialde.com/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                          www.initialde.com
                      </a>
                    </div>
                  </Col>
                </Row>
                </CardFooter>
              </Card>
            </Col>
        </Row>
      </div>
  );

}

export default Register;
