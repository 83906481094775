import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import Moment from "moment";
moment().locale('fr');
momentDurationFormatSetup(moment);

function timeDiffBetweenTwoDateTimes({startDate, endDate}) {
    const ms = endDate.diff(startDate);
    return moment.duration(ms).humanize();
}

function isStartBeforeEndDate({startDate, endDate}) {
    const start = Moment(startDate);
    const end = Moment(endDate);
    return start.isBefore(end);
}

const defaultDays = [
    {
        selected: true,
        day: "lun",
    },
    {
        selected: true,
        day: "mar",
    },
    {
        selected: true,
        day: "mer",
    },
    {
        selected: true,
        day: "jeu",
    },
    {
        selected: true,
        day: "ven",
    },
    {
        selected: true,
        day: "sam",
    },
    {
        selected: true,
        day: "dim",
    },
];

export { timeDiffBetweenTwoDateTimes, isStartBeforeEndDate, defaultDays };