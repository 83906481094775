import React, {useEffect, useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Row,
  Col
} from "reactstrap";
import {child, database, onValue, ref} from "../firebase/init-database";
import {auth} from "../firebase/init-auth";
import EditUserForm from "../components/User/EditUserForm";

function UserProfile() {

  const [user, setUser] = useState([]);
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [society, setSociety] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postal, setPostal] = useState("");
  const [profil, setProfil] = useState("");
  const [network, setNetwork] = useState("");

  const action = "profile.edit";

  useEffect(() => {
    const currentUser = auth.currentUser;
    const userRef = ref(database, 'users/' + currentUser.uid);
    onValue(userRef, snapshot => {
      const user = snapshot.val();
      setUser(user);
      setCity(user?.city);
      setFirstname(user?.firstname);
      setLastname(user?.lastname);
      setCountry(user?.country);
      setPostal(user?.postal);
      setSociety(user?.society);
      setEmail(user?.email);
      setProfil(user?.profil);
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      onValue(netRef, (snap) => {
        setNetwork(snap.key);
      });
    });
  }, []);

    return (
      <>
        <div className="content">
          <Row>
          <Col md="4">
              <Card className="card-user">
                <CardBody>
                  <CardText />
                  <div className="author">
                    <div className="block block-one" />
                    <div className="block block-two" />
                    <div className="block block-three" />
                    <div className="block block-four" />
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar"
                        src={`https://ui-avatars.com/api/?name=${user?.email}&background=random`}
                      />
                      <h5 className="title">{`${user?.firstname} ${user?.lastname}`}</h5>
                    </a>
                    <p className="description">{`${user?.profil}`.toLocaleUpperCase()}</p>
                    <p className="description">{`Reseau: ${network}`}</p>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="button-container">
                    <Button className="btn-icon btn-round" >
                    <a
                        href="https://www.iecran.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                      <img
                          alt="..."
                          src={`https://ui-avatars.com/api/?name=${user?.society}&background=random`}
                      />
                      </a>
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Edition Profil</h5>
                </CardHeader>
                <CardBody>
                  <EditUserForm
                      action={action}
                      setSociety={(society) => setSociety(society)} society={society}
                      setLastname={(last) => setLastname(last)} lastname={lastname}
                      setFirstname={(first) => setFirstname(first)} firstname={firstname}
                      setEmail={(mail) => setEmail(mail)} email={email}
                      setPassword={(pass) => setPassword(pass)} password={password}
                      setCountry={(country) => setCountry(country)} country={country}
                      setCity={(city) => setCity(city)} city={city}
                      setPostal={(postal) => setPostal(postal)} postal={postal}
                      setProfil={(profil) => setProfil(profil)} profil={profil}
                  />
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit">
                    Valider
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
}

export default UserProfile;
