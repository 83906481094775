function convertSizeFileToByte (fileSize = '') {
    const units = ['KB','MB', 'GB'];

    let fileUnit = '';
    let fileSizeByte = 0;

    units.forEach(unit => {
        if(fileSize.toLocaleLowerCase().includes(unit.toLocaleLowerCase())) {
            fileUnit = unit;
            fileSizeByte = +(fileSize.toLocaleUpperCase().replace(fileUnit, ''));
        }
    });

    switch (fileUnit.toLocaleUpperCase()) {
        case 'KB':
            fileSizeByte *= Math.pow(1024, 1);
            break;
        case 'MB':
            fileSizeByte *= Math.pow(1024, 2);
            break;
        case 'GB':
            fileSizeByte *= Math.pow(1024, 3);
        break;
        default:
            fileSizeByte = 0;
            break;
    }

    return fileSizeByte;

}

function convertByteToHumanRedeableSize(bytes, decimals = 2) {
    if (bytes === 0) return '0';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function convertByteToPercentage(sizeByte = 0, totalStockage = 100) {
    const sizeByteToGB = sizeByte / (Math.pow(1024, 3));
    const pourcentage = parseInt((sizeByteToGB * 100) / totalStockage + "");
    return pourcentage;
}

export { convertSizeFileToByte, convertByteToHumanRedeableSize, convertByteToPercentage}