import React, {useEffect, useState} from "react";
import ScreenCard from "./ScreenCard";
import {Col, Row} from "reactstrap";

function ScreenList ({
    screens,
    playlists,
    groups,
    screenModalRef
}) {

    const [videos, setVideos] = useState([])
    const [messages, setMessages] = useState([])

    useEffect(() => {
        for (let key in screens) {
            playlists.forEach(playlist => {
                let groups = playlist?.groups?.filter(group => group.id === screens[key].screenGroup) ?? [];
                groups.forEach(group => {
                    if(playlist?.videos && playlist?.videos.length > 0) {
                        const newVideos = [];
                        playlist?.videos.forEach(video => {
                            newVideos.push({
                                src: video.videoUrl,
                                type: 'video/mp4',
                                groupId: group.id,
                            })
                        })
                        setVideos(newVideos);
                    }
                })
            })

            const newMessages = groups.filter(group => group.id === screens[key].screenGroup)[0]?.messages
            setMessages(newMessages ?? []);
        }
    }, [])

    return (
        <Col md="12">
            <Row>
                <ScreenCard
                    screenModalRef={screenModalRef}
                    screens={screens} videos={videos} messages={messages} />
            </Row>
        </Col>
    )
}

export default ScreenList;