import {auth} from "./init-auth";
import {database, get, onValue, push, ref, remove, set} from "./init-database";
import {notify} from "../utils/notify";

export const addGroup = ({
    group,
                         }) =>{
    if(group !== "" && group !== null && group !== " ") {
        const user=auth.currentUser;
        const usersRef = ref(database, 'users/' + user.uid);
        onValue(usersRef,
            (snapshot) => {
                const groupsRef = ref(database, 'networks/' + snapshot.val().network + '/groups/');
                push(groupsRef, {
                        name: group,
                    }
                );

            }, {
                onlyOnce: true
            });
    }
}

export const updateGroup = ({
    id = 0,
    group
}) => {
        const user=auth.currentUser;
        const usersRef = ref(database, 'users/'+ user.uid);
        onValue(usersRef,
            (snapshot) => {
                const groupRef = ref(database, 'networks/' + snapshot.val().network + '/groups/' + id + '/name')
                set(groupRef, group)
        }, {
            onlyOnce: true
        })
}
export const addDefaultGroup = ({networkId, networkName}, callback) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/' + user.uid);
    onValue(usersRef,
        (snapshot) => {
            const defaultGroupRef = ref(database, 'networks/' + snapshot.val().network + '/groups/' + networkId);
            get(defaultGroupRef ).then((snapshot) => {
                if (!snapshot.exists()) {
                    set(defaultGroupRef, {
                            name: "_" + networkName,
                        }
                    );
                }
                callback();
            }).catch((error) => {
                console.error(error);
            });
        }, {
            onlyOnce: true
        });

}

export const removeGroup = ({id = 0}) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            const groupRef = ref(database, 'networks/' + snapshot.val().network + '/groups/' + id)
            remove(groupRef);
        }, {
            onlyOnce: true
        })
}

export const removeGroupPlaylistRef = ({id = 0}) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            const playlistRef = ref(database, 'networks/' + snapshot.val().network + '/groups/' + id + "/playlistRef/")
            remove(playlistRef);
        }, {
            onlyOnce: true
        })
}