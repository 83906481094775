import {CardBody, FormGroup, Input, Label, Table} from "reactstrap";
import StorageTable from "./StorageTable";
import React, {forwardRef, useImperativeHandle, useState} from "react";

const StorageBodyCard = forwardRef(({
    folders,
    setFolders,
    notificationAlertRef,
    checkedFolders,
}, ref) => {

    const [toggleCheckedAllFolders, setToggleCheckedAllFolders] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleCheckedForAllFolders(e) {
            toggleCheckedForAllFolders(e)
        },

        setToggleCheckedAllFolders(state) {
            setToggleCheckedAllFolders(state)
        }
    }), []);

    const toggleCheckedForAllFolders = (event) => {
        if(folders.length > 0) {
            const newFolders = folders;
            folders.forEach(folder => {
                folder.checked = event.target.checked;
            })
            setFolders([...newFolders]);
            setToggleCheckedAllFolders(!toggleCheckedAllFolders);
        } else {
            event.preventDefault();
        }
    }

    return (
        <CardBody>
            <Table className="tablesorter" responsive>
                <thead className="text-primary">
                    <tr>
                        <th className="text-center">
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        onClick={(e) => toggleCheckedForAllFolders(e)}
                                        checked={toggleCheckedAllFolders}
                                        type="checkbox"
                                    />
                                    <span className="form-check-sign">
                                  <span className="check" />
                                </span>
                                </Label>
                            </FormGroup>
                        </th>
                        <th>Titre</th>
                        <th className="text-center">Média</th>
                        <th className="text-center">Taille</th>
                        <th className="text-center">Date</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <StorageTable
                        checkedFolders={checkedFolders}
                        setFolders={setFolders}
                        folders={folders}
                        notificationAlertRef={notificationAlertRef}
                        setToggleCheckedAllFolders={state => setToggleCheckedAllFolders(state)}
                        toggleCheckedAllFolders={toggleCheckedAllFolders}
                    />
                </tbody>
            </Table>
        </CardBody>
    )
});

export default StorageBodyCard;