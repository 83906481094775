import React, {useRef, useState} from 'react';
import {Col, FormGroup, Row} from "reactstrap";
import Multiselect from "multiselect-react-dropdown";

function BroadcastGroupInput({
     selectedGroups = [],
     setSelectedGroups,
     groups = [],
    resetGroups
}) {
    const multiselectRef = useRef(null);

    useState(() => {
        resetGroups(multiselectRef);
    })

    return (
        <Row>
            <Col md="12">
                <FormGroup>
                    <label>A diffuser sur</label>
                    <Multiselect
                        ref={multiselectRef}
                        className="react-select info"
                        classNamePrefix="react-select"
                        placeholder="Ajouter un groupe..."
                        displayValue="name"
                        selectedValues={selectedGroups}
                        onSelect={(group) => {
                            setSelectedGroups(group);
                        }}
                        onRemove={(selectedList, selectedIGroup) => {
                            let newList = selectedGroups.filter(
                                (group) => group.id !== selectedIGroup.id
                            );
                            setSelectedGroups([...newList]);
                        }}
                        options={groups}
                        style={{
                            tags: {
                                background: "primary",
                            },
                            multiselectContainer: {
                                color: "red",
                            },
                            optionContainer: {
                                background: "#1e1e2f",
                            },
                        }}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
}

export default BroadcastGroupInput;