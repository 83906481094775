import React, { Component } from 'react';

function notify ({ref, type = "success", place = "br", message = ""}) {    
    const options = {
      place: place,
      message: (
        <div>
            {message}
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3
    };

    ref?.current.notificationAlert(options);
    
  }

  export { notify }