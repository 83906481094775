import React, {useEffect, useRef, useState} from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Input,
  Button,
  ButtonGroup,
  Label,
  Row,
  Col
} from "reactstrap";

import classNames from "classnames";
import {
  child,
  database,
  onValue,
  ref,

} from "../firebase/init-database.js";
import { auth } from "../firebase/init-auth.js";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { notify } from "utils/notify.jsx";
import PlaylistTable from "../components/Playlist/PlaylistTable";
import {removeAllPlaylists, removePlaylist} from "../firebase/playlist";
import {useHistory} from "react-router-dom";

function Playlist () {
  const [playlists, setPlaylists] = useState([]);
  const [networkId, setNetworkId] = useState("");
  const [removeAllPlaylistsConfirmAlertState, setRemoveAllPlaylistsConfirmAlertState] = useState(null);
  const [toggleCheckedAllPlaylists, setToggleCheckedAllPlaylists] = useState(false);

  const notificationAlert = useRef();

  const history = useHistory();

  const toggleCheckedForAllPlaylists = (event) => {
    if (playlists.length > 0) {
      const newPlaylists = playlists;
      newPlaylists.forEach((playlist) => {
        playlist.checked = event.target.checked;
      });
      setToggleCheckedAllPlaylists(!toggleCheckedAllPlaylists);
      setPlaylists([...newPlaylists]);
    } else {
      event.preventDefault();
    }
  };

  const toggleCheckedPlaylist = ({ playlistId = 0 }) => {
    const newPlaylists = playlists;
    newPlaylists.forEach((playlist) => {
      if (playlist.id === playlistId) {
        playlist.checked = !playlist.checked;
      }
    });
    setToggleCheckedAllPlaylists(checkedPlaylists().length === playlists.length);
    setPlaylists([...newPlaylists]);
  };

  const checkedPlaylists = () => playlists.filter((playlist) => playlist.checked);

  const removeSelectedPlaylists = () => {
    if (toggleCheckedAllPlaylists) {
      removeAllPlaylists({
        playlists
      });
      setToggleCheckedAllPlaylists(false);

      notify({
        ref: notificationAlert,
        type: "success",
        place: "br",
        message: `${checkedPlaylists().length} playlists supprimées`,
      });
      return;
    }

    if (checkedPlaylists().length > 0) {
      const playlistsToRemove = checkedPlaylists();
      playlistsToRemove.forEach((playlist) => {
          removePlaylist({
            id: playlist.id,
            playlists
          });
      });
      notify({
        ref: notificationAlert,
        type: "success",
        place: "br",
        message: `${checkedPlaylists().length} playlists supprimées`,
      });
    }
  };


  const removeAllPlaylistsConfirmAlert = () => {
    if (checkedPlaylists().length > 0) {
      setRemoveAllPlaylistsConfirmAlertState(
          <SweetAlert
            warning
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title={`Voulez-vous supprimez ces ${
              checkedPlaylists().length
            } playlists ?`}
            confirmBtnText="Oui"
            cancelBtnText="Annuler"
            onConfirm={() => {
              removeSelectedPlaylists();
              hideRemoveAllPlaylistsConfirmAlert();
            }}
            onCancel={() => hideRemoveAllPlaylistsConfirmAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            btnSize="md"
          />
      );
    }
  };

  const hideRemoveAllPlaylistsConfirmAlert = () =>
    setRemoveAllPlaylistsConfirmAlertState(null);

  useEffect(() => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);

    onValue(usersRef, (snapshot) => {
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      const playlistsRef = child(netRef, "/playlist/");

      onValue(netRef, (snap) => {
        setNetworkId(snap.key);

        onValue(playlistsRef, (snap) => {
          if(snap.exists()) {
            const result = snap.val();
            const playlists = [];

            for (let key in result) {
              playlists.push({
                id: key,
                name: result[key]?.title,
                groups: result[key]?.groupsRef ?? [],
                videos: result[key]?.videos,
                startDate: result[key]?.startDate,
                endDate: result[key]?.endDate,
                checked: false
              });
            }
            setPlaylists(playlists);
          }
        })

      }, {
        onlyOnce: true
      })

    });

  }, [])


  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Col md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h4">Playlists</CardTitle>
                  {/* <p className="category">Here is a subtitle for this table</p> */}
                </Col>
                   <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        tag="label"
                        className={classNames("btn-simple", {
                          active: false
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => removeAllPlaylistsConfirmAlert()}
                        disabled={!(checkedPlaylists().length > 1)}
                      >
                        <input
                          defaultChecked
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Supprimer
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-trash-simple" />
                        </span>
                      </Button>
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: true
                        })}
                        onClick={() => history.push({pathname: "/admin/playlist/add"})}
                        disabled={checkedPlaylists().length > 1}
                      >
                        <input
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Nouvelle playlist
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-bullet-list-67" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
            </CardHeader>
            <CardBody>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                <tr>
                  <th className="text-center">
                    <FormGroup check>
                      <Label check>
                        <Input
                         onChange={(e) =>
                          toggleCheckedForAllPlaylists(e)
                        }
                        checked={toggleCheckedAllPlaylists}
                        type="checkbox"
                        />
                        <span className="form-check-sign">
                          <span className="check" />
                        </span>
                      </Label>
                    </FormGroup>
                  </th>
                  <th>Titre</th>
                  <th className="text-center">Jouer sur</th>
                  <th className="text-center">Période</th>
                  <th className="text-center">Durée</th>
                  <th className="text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                    <PlaylistTable
                        playlists={playlists} networkId={networkId}
                        checkedPlaylists={checkedPlaylists}
                        toggleCheckedAllPlaylists={toggleCheckedAllPlaylists}
                        toggleCheckedPlaylist={toggleCheckedPlaylist}
                        notificationAlert={notificationAlert}
                    />
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </div>
      {removeAllPlaylistsConfirmAlertState}
   </>
  );

}

export default Playlist;
