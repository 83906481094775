import {Col, FormGroup, Input, Row} from "reactstrap";
import BroadcastGroupInput from "./BroadcastGroupInput";
import StartAndEndDate from "../StartAndEndDate";
import DaysCheckbox from "../DaysCheckbox";
import React from "react";

function InputsForm({
    playlistName,
    setPlaylistName,
    groups,
    selectedGroups,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    days,
    setDays,
    setSelectedGroups,
    handleResetGroupInput

                    }) {
    return(
        <Col md="8">
            <Row>
                <Col md="12">
                    <FormGroup>
                        <label>Titre</label>
                        <Input
                            id="playlistName"
                            placeholder="Nom de la playlist"
                            onChange={(event) => setPlaylistName(event.target.value)}
                            type="text"
                            value={playlistName}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <BroadcastGroupInput
                groups={groups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                resetGroups={handleResetGroupInput}
            />
            <StartAndEndDate
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate} // timestamp millisecond format
                setEndDate={setEndDate}
            />
            <DaysCheckbox days={days} setDays={setDays} />
        </Col>
    )
}

export default InputsForm;