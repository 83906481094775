import {Button, CardFooter, Col, FormGroup, Input, Progress, Row} from "reactstrap";
import {addFolder} from "../../firebase/storage";
import {convertByteToHumanRedeableSize, convertByteToPercentage} from "../../utils/convertion";
import React from "react";
import {notify} from "../../utils/notify";

function StorageFooterCard({
    folder,
    setFolder,
    stockageSize,
    notificationAlertRef
                    }) {

    const submit = () => {
        addFolder({
            folder,
            setFolder
        });
        notify({ref: notificationAlertRef, type: "success", place: "br", message: `Le dossier ${folder} a été ajouté`});
    }

    return (
        <CardFooter>
            <Col md='12'>
                <div className="float-left">
                    <Row>
                        <Col md="auto">
                            <FormGroup>
                                <Input
                                    id="folder"
                                    placeholder="Nouveau dossier"
                                    type="text"
                                    value ={folder}
                                    onChange={(e) => setFolder(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Button style={{margin: '0px'}} className="btn btn-info" onClick={submit} disabled={!(folder && folder !== " ")} >Ajouter</Button>
                        </Col>
                    </Row>
                </div>
                <div className="float-right" style={{width: '150px'}}>
                    <h5 className="text-right">{convertByteToHumanRedeableSize(stockageSize)} / 100Go</h5>
                    <Progress animate value={convertByteToPercentage(stockageSize) > 0 ? convertByteToPercentage(stockageSize) : 0} />
                </div>
            </Col>
        </CardFooter>
    )
}
export default StorageFooterCard;