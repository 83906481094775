import React, {useEffect, useRef, useState} from "react";
import { database, ref, onValue } from "../firebase/init-database.js";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  ButtonGroup
} from "reactstrap";
import classNames from "classnames";
import config from "../config/default.js";
import NotificationAlert from "react-notification-alert";
import {useHistory} from "react-router-dom";
import UsersTable from "../components/User/UsersTable";

function Users() {
  const [users, setUsers] = useState([]);


  const notificationAlert = useRef();

  const history = useHistory();



  useEffect(() => {
    const usersRef = ref(database, "users");
    onValue(usersRef, snapshot => {
      const newUsers = [];
      snapshot.forEach(snap => {
        newUsers.push({...snap.val(), uid: snap.key});
      });
      setUsers(newUsers);
    });
  }, []);


  return (
    <>
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlert} />
        </div>
        <Row>
          <Col md="12">
            <Card>
            <CardHeader>
              <Row>
                <Col className="text-left" sm="6">
                  <CardTitle tag="h4">Users</CardTitle>
                  {/* <p className="category">Here is a subtitle for this table</p> */}
                </Col>
                   <Col sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: true
                        })}
                        onClick={() => history.push({pathname: "/admin/user/add"})}
                      >
                        <input
                          className="d-none"
                          name="options"
                          type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                          Nouvel utilisateur
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-bullet-list-67" />
                        </span>
                      </Button>
                    </ButtonGroup>
                  </Col>
                </Row>
            </CardHeader>
              <CardBody>
                <UsersTable notificationAlert={notificationAlert} users={users} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Users;
