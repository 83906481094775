import {auth} from "./init-auth";
import {child, database, get, onValue, push, ref, remove, set} from "./init-database";
import Moment from "moment/moment";
import {getDownloadURL, refFromStorage, storage, uploadBytes} from "./init-storage";
import {addDefaultGroup, removeGroupPlaylistRef} from "./group_firebase";
export const addPlaylist = ({
    videos,
    files,
    playlistName,
    networkId,
    networkName,
    startDate,
    endDate,
    days,
    selectedGroups,
    setIsVideoUploading,
    history,
    initAddPlaylistForm
                            }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);

    onValue(usersRef,
        async (snapshot) => {
            const netRef = ref(database, "networks/" + snapshot.val()?.network);

            let videosRef = [];

            // Saving the videos files get from disk to default folder
            if(files && files.length > 0) {

                setIsVideoUploading(true);

                await addDefaultFolder({
                    networkId: networkId,
                    networkName: networkName
                }).then(async() => {
                    videosRef = await addFilesToDefaultFolder({
                        files, networkId, networkName,
                        startDate, endDate, days, videosRef
                    });
                });
            }


            setTimeout(() => {
                setIsVideoUploading(false);
                videos.forEach(video => videosRef.push({
                    title: video.label,
                    videoUrl: video.value,
                    startDate: startDate,
                    endDate: endDate > 0 ? endDate : 0,
                    days: days,
                }));

                const playlistRef = child(netRef, "/playlist/");
                if(selectedGroups && selectedGroups.length > 0) {
                    const playlistKey = push(playlistRef, {
                        title: playlistName,
                        groupsRef: selectedGroups,
                        videos: videosRef,
                        startDate: startDate,
                        endDate: endDate > 0 ? endDate : 0,
                        days: days,
                    }).key;

                    const groupsRef = child(netRef, "/groups/");
                    selectedGroups.forEach((group) => {
                        const playlistRef = child(groupsRef, group.id + "/playlistRef/");
                        removeGroupFromPlaylistGroupsRefs(playlistKey, group.id);
                        set(playlistRef, playlistKey);
                    });
                } else {
                    // Save the playlist to the default group if no groups were selected
                    addDefaultGroup({networkId, networkName},() => {
                        const playlistKey = push(playlistRef, {
                            title: playlistName,
                            groupsRef: [{id: networkId, name: networkName}],
                            videos: videosRef,
                            startDate: startDate,
                            endDate: endDate > 0 ? endDate : 0,
                            days: days,
                        }).key;

                        const groupsRef = child(netRef, "/groups/");
                        const playlist = child(groupsRef, networkId + "/playlistRef/");
                        removeGroupFromPlaylistGroupsRefs(playlistKey, networkId);
                        set(playlist, playlistKey);
                    });
                }

                initAddPlaylistForm();

                history.push("/admin/playlists");
            }, 3000);
        }, {
            onlyOnce: true
    })


}


export const updatePlaylist = ({
   videos,
   files,
   playlistName,
   playlistId,
   networkId,
   networkName,
   startDate,
   endDate,
   days,
   selectedGroups,
   setIsVideoUploading,
   history,
                               }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);

    onValue(usersRef,
            async (snapshot) => {
                const netRef = ref(database, "networks/" + snapshot.val()?.network);
                const playlistRef = child(netRef, "/playlist/" + playlistId);


                const startD = Moment.isMoment(startDate) ? startDate.valueOf() : startDate;
                const endD = Moment.isMoment(endDate) ? endDate.valueOf() : (endDate > 0 ? endDate : 0);

                let videosRef = [];
                if(files && files.length > 0) {
                    setIsVideoUploading(true);
                    await addDefaultFolder({
                        networkId: networkId,
                        networkName: networkName,
                    }).then(async () => {
                        videosRef = await addFilesToDefaultFolder({
                            files, networkId, networkName,
                            startDate, endDate, days, videosRef
                        });
                    });
                }

                setTimeout(() => {
                    videos.forEach(video => videosRef.push({
                        title: video.label,
                        videoUrl: video.value,
                        startDate: startD,
                        endDate: endD,
                        days: days,
                    }));

                    const groups = (selectedGroups && selectedGroups.length > 0) ? selectedGroups : [{id: networkId, name: networkName}]
                    const groupsRef = child(netRef, "/groups/");
                    groups.forEach((group) => {
                        const playlist = child(groupsRef, group.id + "/playlistRef/");
                        removeGroupFromPlaylistGroupsRefs(playlistId, group.id);
                        set(playlist, playlistId);
                    });

                    set(playlistRef, {
                        title: playlistName,
                        groupsRef: groups,
                        videos: videosRef,
                        startDate: startD,
                        endDate: endD,
                        days: days,
                    })

                    setIsVideoUploading(false);
                    history.push("/admin/playlists");
                }, 3000);

            }, {
                onlyOnce: true
    })


}

export const removePlaylist = ({
    id,
    playlists = [],
}) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            const playlist = playlists.filter(playlist => playlist.id === id)[0];
            const playlistGroups = playlist?.groups;

            playlistGroups.forEach(group => removeGroupPlaylistRef({id: group.id}));

            const playlistRef = ref(database, 'networks/'+ snapshot.val()?.network + '/playlist/' + id);

            remove(playlistRef);
        }, {
            onlyOnce: true
        })
}

export const removeAllPlaylists = ({
    playlists
                                   }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    onValue(usersRef, (snapshot) => {
        playlists.forEach(playlist => {
            const groups = playlist?.groups;
            groups.forEach(group => removeGroupPlaylistRef({id: group.id}))
        })
        const netRef = ref(database, "networks/" + snapshot.val()?.network);
        const playlistRef = child(netRef, "/playlist/");
        remove(playlistRef);
    });
};

const removeGroupFromPlaylistGroupsRefs = (playlistKey, groupId) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/' + user.uid);
    onValue(usersRef,
        (snapshot) => {
            const playlistsRef = ref(database, 'networks/' + snapshot.val().network + '/playlist');
            onValue(playlistsRef, (snap) => {
                const playlists = snap.val();
                for (let key in playlists) {
                    if(key !== playlistKey) {
                        const groups = playlists[key]?.groupsRef;
                        groups?.forEach((group, index) => {
                            if(groupId === group.id) {
                                const groupRef = ref(database, 'networks/' + snapshot.val().network + '/playlist/' + key + "/groupsRef/" + index + '/');
                                remove(groupRef);
                            }
                        })
                    }
                }
            }, {onlyOnce: true});
        }, {
            onlyOnce: true
        });
}


const addFilesToDefaultFolder = async ({
    files,
    networkId,
    networkName,
    startDate,
    endDate,
    days,
    videosRef = []
}) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/' + user.uid);

    return new Promise((resolve) => { onValue(usersRef,
        async (snapshot) => {
            const folderRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + networkId +"/videos");
            const startD = Moment.isMoment(startDate) ? startDate.valueOf() : startDate;
            const endD = Moment.isMoment(endDate) ? endDate.valueOf() : (endDate > 0 ? endDate : 0);
            for(const file of files) {
                const videoRef = refFromStorage(storage, snapshot.val()?.network + '/' + networkName + '/' + file.name);

                const metadata = {
                    contentType: file.type,
                    size: file.sizeReadable
                };

                await getDownloadURL(videoRef)
                    .then(async (url) => {
                        await uploadBytes(videoRef, file, metadata).then(_ => {
                            videosRef.push({
                                title: file.name,
                                videoUrl: url,
                                startDate: startD,
                                endDate: endD,
                                days: days,
                            })
                        })
                    })
                    .catch(async (error) => {
                        if (error.code === 'storage/object-not-found') {
                            await uploadBytes(videoRef, file, metadata)
                                .then(async () => {
                                    getDownloadURL(videoRef).then(url => {
                                        push(folderRef,  {
                                            url: url,
                                            label: file.name,
                                            size: file.sizeReadable
                                        })
                                        videosRef.push({
                                            title: file.name,
                                            videoUrl: url,
                                            startDate: startD,
                                            endDate: endD,
                                            days: days,
                                        })
                                    })
                                })
                        }
                    })
            }
            resolve(videosRef);
        })})
}

export const addDefaultFolder = ({
    networkId,
    networkName,
                                 }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);

    return new Promise((resolve) => {
        onValue(usersRef,
            (snapshot) => {
                const defaultGroupRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + networkId);
                get(defaultGroupRef ).then((snapshot) => {
                    if (!snapshot.exists()) {
                        set(defaultGroupRef, {
                                label: "_" + networkName,
                                timestamp: Date.now()
                            }
                        );
                    }
                    resolve();
                }).catch((error) => {
                    console.error(error);
                });
            }, {
                onlyOnce: true
            })
    });
}

export const getFolders = (callback) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);

    onValue(usersRef, (snapshot) => {
        const foldersRef = ref(database, 'networks/' + snapshot.val()?.network + '/folders/');
        onValue(foldersRef, (snap) => {
            const result = snap.val();
            const folders = [{
                value: "selectAll",
                label: "Tout selectionner",
                checked: false,
                className: "select-all",
                children: []
            }];
            for (let key in result) {
                const videos = [];

                if(result[key]?.videos) {

                    const videoRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + key + "/videos");

                    onValue(videoRef, snapshot => {
                        const videoIds = snapshot.val();
                        for (let videoKey in videoIds) {
                            videos.push({
                                label: videoIds[videoKey].label,
                                value: videoIds[videoKey].url,
                                size: videoIds[videoKey].size,
                                checked: false,
                                parentId: key,
                                className: "icon video",
                                tagClassName: "icon video",
                            })
                        }
                    })
                }

                folders.push({
                    id: key,
                    value: result[key].label,
                    label: result[key].label,
                    className: "icon folder",
                    checked: false,
                    tagClassName: "icon folder",
                    children: videos
                });
            }

            callback(folders);
        }); }
    );
};