import axios from "axios";
import config from "../config/default";
import {database, push, ref, remove, set} from "./init-database";
import {auth, createUserWithEmailAndPassword} from "./init-auth";
import {notify} from "../utils/notify";

export const addUser = ({lastname, firstname, profil, email, password, society, city, country, postal, notificationAlert, history}) =>{
    axios.post(`${config.server.url}/admin/user`, {
        email,
        password,
        displayName: firstname + " " + lastname,
    })
        .then((res) => {
            if(res && res.status === 200) {
                const networksRef = ref(database, "networks/");
                const newNetwork = push(networksRef, {
                    name: society
                });

                const userRef = ref(database, 'users/' + res.data.uid);

                set(userRef, {
                    lastname: lastname,
                    firstname: firstname,
                    society: society,
                    email: email,
                    city: city,
                    country: country,
                    postal: postal,
                    profil: profil,
                    network: newNetwork.key,
                    currentDateTime: Date().toLocaleString()
                })
                .then(() => {
                    const message = profil === "admin" ? `Le réseau ${society} a été crée avec son manager` : "L'utilisateur a été crée";
                    notify({ ref: notificationAlert, type: "success", place: "br", message: message });
                    setTimeout(() => history.push("/admin/users"), 1000);
                })
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            let notifyMessage = "Une erreur s'est produite lors de la création de compte, veuillez recommencer";

            if(errorCode === "auth/email-already-in-use")
                notifyMessage = "L'adresse mail existe déjà";

            notify({ref: notificationAlert, type: "danger", place: "tc", message: notifyMessage});
        });
}

export const updateUser = ({uid, lastname, firstname, profil, email, society, city, country, postal, notificationAlert, history}) => {

    axios.put(`${config.server.url}/admin/user/${uid}`, {
        email: email,
        displayName: firstname + " " + lastname
    }).then(res => {
        if(res && res.status === 200) {
            const userRef = ref(database, 'users/' + uid);
            set(userRef, {
                lastname: lastname,
                firstname: firstname,
                society: society,
                email: res.data.email,
                city: city,
                country: country,
                postal: postal,
                profil: profil,
                currentDateTime: Date().toLocaleString()
            })
                .then(() => {
                    notify({
                        ref: notificationAlert,
                        type: "success",
                        place: "br",
                        message: "L'utilisateur a été modifié"
                    });
                    setTimeout(() => history.push("/admin/users"), 1000);
                })
        }
    });



}
export const removeUser = ({uid, network}) => {
    axios({
        method: 'delete',
        url: `${config.server.url}/admin/user/${uid}`,
        withCredentials: false,
        responseType: 'json',
    }).then((res) => {
        if(res && res.status === 200) {
            const usersRef = ref(database, "users/" + uid);
            remove(usersRef);
            const netRef = ref(database, "networks/" + network);
            remove(netRef);
        }
    }).catch(e => console.log(e));

}

export const addSociety = ({email, password, society, address, city, country, postal}) => {
    createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const networksRef = ref(database, "networks/");
            const newNetwork = push(networksRef, {
                name: society
            });

            const networkKey = newNetwork.key;
            const userRef = ref(database, 'users/' + userCredential.user.uid);
            set(userRef, {
                lastname: society,
                firstname: "",
                society: society,
                email: email,
                address: address,
                city: city,
                country: country,
                postal: postal,
                profil: "manager",
                network: networkKey,
                currentDateTime: Date().toLocaleString(),
            })
        });
}