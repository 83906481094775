import React from "react";
import { database, ref, onValue } from "../firebase/init-database.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col, Button, Modal, ModalBody, ModalFooter
} from "reactstrap";

class Tools extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false,
      materiel: [],
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  } 

  componentDidMount() {
    const matRef = ref(database, 'materiel');

    onValue(matRef, (snapshot) => {
      let materiel = snapshot.val();
      let newMaterielState = [];
      for (let materie in materiel){
        newMaterielState.push({
          id:materie,
          libelle: materiel[materie].libelle,
          quantity: materiel[materie].quantity
        });
        
      }
      this.setState({
        materiel: newMaterielState
      });
    });
  }

  render() {
    const modalStyle = {
      overlay: {
        position: "fixed",
        top: "10px",
        width: "700px",
        left: "350px",
        right: "5px",
        bottom: "5px"
      }
    };

    return (
      <>
        <div className="content">
          <Col md="12">
            <Card className="card-plain">
              <CardHeader>
                <Row>
                  <Col className="text-left" sm="10">
                    <CardTitle tag="h4">Banque à matérieaux</CardTitle>
                    {/* <p className="category">Here is a subtitle for this table</p> */}
                  </Col>
                  <a href="/admin/add-tools">
                    <Button className="float-right" color="info" onClick={this.showModal}  isOpen={false} toggle={true} right>
                      <i class="tim-icons icon-simple-add"></i> Ajouter un matériel
                    </Button>
                  </a>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                  <tr>
                    <th>Nom matériel</th>
                    <th className="text-center">Quantité</th>
                    <th className="text-center">Détail</th>
                  </tr>
                  </thead>
                  <tbody>
                  {this.state.materiel.map((materie)=> {
                    return (
                  <tr>
                    <td>{materie.libelle}</td>
                    <td className="text-center">{materie.quantity}</td>
                    <td className="text-center">
                      <a>
                        <Button onClick={this.handleOpenModal} className="btn-icon btn btn-success">
                          <i class="tim-icons icon-notes" />
                        </Button>
                      </a>
                    </td>
                  </tr>
                  )})}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <div>
            <Col md="3"></Col>
            <Col md="5">
            <Modal isOpen={this.state.showModal} style={modalStyle}>
              <div className="text-center">
                 <div><b>DETAIL DU MATERIEL</b></div>
              </div>
              <ModalBody>
                <span className="text-right"><b>Box internet 1</b></span>
                <div className="text-left" style={{marginLeft:"15px"}}>
                  <div> Identifiant : </div>
                  <div> Lieu : </div>
                  <div> Etat : </div>
                </div><hr></hr>
                <span className="text-right"><b>Box internet 2</b></span>
                <div className="text-left" style={{marginLeft:"15px"}}>
                  <div> Identifiant : </div>
                  <div> Lieu : </div>
                  <div> Etat : </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-danger" onClick={this.handleCloseModal} >Fermer</button>
              </ModalFooter>
            </Modal>
            </Col>
            <Col md="3"></Col>
          </div>
        </div>
      </>
    );
  }
}
export default Tools;

