import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import placeholderImg from "../../assets/img/image_placeholder.webp";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import SelectedFilesList from "./SelectedFilesList";
import FilesFromDevice from "../FilesFromDevice";
import {addVideos} from "../../firebase/storage";
import {notify} from "../../utils/notify";

const NewVideoModal = forwardRef(({
    folders,
    notificationAlertRef
}, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [files, setFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [fileFolderId, setFileFolderId] = useState("");

    useImperativeHandle(ref, () => ({
        handleOpenModal() {
            setShowModal(true)
        },
        handleCloseModal() {
            setShowModal(false)
        }
    }), []);

    const validateAddVideosForm = () => {
        return (files.length > 0 && fileFolderId !== "");
    }

    const submit = () => {
        addVideos({
            files,
            folders,
            fileFolderId
        });
        setFiles([]);
        setFileFolderId("");
        setShowModal(false);
        const notifyMessage = files.length === 1 ? "La vidéo a été ajoutée" : `${files.length} vidéos ont été ajoutées`;
        notify({ref: notificationAlertRef, type: "success", place: "br", message: notifyMessage});
    }

    return (
        <Modal size="lg" isOpen={showModal} toggle={() => {
            setShowModal(!showModal);
            setFiles([]);
        }} className="modal modal-black modal-content">
            <ModalHeader className="modal-header" toggle={() => {
                setShowModal(false);
                setFiles([]);
            }}>
                <div className="modal-title">
                    <div><b>Ajout de vidéos</b></div>
                </div>
            </ModalHeader>
            <ModalBody>
                <Col md="12">

                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label for="dossier">Dossier</Label>
                                <Input type="select" name="fileFolderId" className="form-control" onChange={(e) => {
                                    setFileFolderId(e.target.value)
                                }}  style={{backgroundColor:"#1e1e2f"}} id="dossier">
                                    <option defaultChecked value=""></option>
                                    {folders.map( folder => <option value={folder.id}>{folder.label}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div>Média</div>
                            <div className="files text-center">
                                <FilesFromDevice setLoader={setLoader} files={files} setFiles={(files) => setFiles(files)}>
                                    <img src={placeholderImg ?? "https://demos.creative-tim.com/black-dashboard-pro-react/static/media/image_placeholder.ae264cfc.jpg"} width={150}  alt="placeholder image"/>
                                </FilesFromDevice>
                                <SelectedFilesList files={files} setFiles={setFiles} loader={loader} />
                            </div>
                        </Col>
                    </Row>
                </Col>

            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-danger" onClick={() => {
                    setShowModal(false);
                    setFiles([]);
                }} >Annuler</Button>
                <Button className="btn btn-info" onClick={submit} disabled={!validateAddVideosForm()}>Enregistrer</Button>
            </ModalFooter>
        </Modal>

    );
});

export default NewVideoModal;