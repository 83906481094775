import {Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {removeScreen} from "../../firebase/screen";
import {notify} from "../../utils/notify";

const NewScreenModal  = forwardRef(({
    notificationAlertRef,
    screens,
    groups,
    macAddress,
    setMacAddress,
    screenName,
    setScreenName,
    screenGroup,
    setScreenGroup,
    lat,
    setLat,
    lon,
    setLon,
    addScreen,
    updateScreen
}, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [screenModalAction, setScreenModalAction] = useState(screenModalAction);
    const [removeOneScreenConfirmAlertState, setRemoveOneScreenConfirmAlertState] = useState(null);

    useImperativeHandle(ref, () => {
        return {
            handleOpenModal({action, screenId}) {
                if(action === "update") {
                    hydrateUpdateScreenModal({screenId})
                } else {
                    initScreenModal();
                }
                setScreenModalAction(action);
                setShowModal(true);
            },
            removeOneScreenConfirmAlert({screenId, screenName}) {
                removeOneScreenConfirmAlert({screenId, screenName})
            }
        };
    }, [notificationAlertRef]);

    const validateScreen = () => {
        return (
            screenName !== "" && screenName != null &&
            macAddress!=="" && macAddress != null && macAddress !== " "
        )
    }

    const initScreenModal = () => {
        setScreenName("");
        setScreenGroup("");
        setLon(0);
        setLat(0);
        setMacAddress("");
    }
    const hydrateUpdateScreenModal = ({screenId}) => {
        const screen = screens.filter(screen => screen.address === screenId)[0];
        setScreenName(screen?.name);
        setScreenGroup(screen?.screenGroup);
        setLon(screen?.lon ?? 0);
        setLat(screen?.lat ?? 0);
        setMacAddress(screen?.address);
    }

    const removeOneScreenConfirmAlert = ({screenId, screenName}) => {
        setRemoveOneScreenConfirmAlertState(
            <SweetAlert
                warning
                showCancel
                style={{display: "block", marginTop: "-100px"}}
                className="text-muted sweetGroupConfirm"
                title={`Voulez-vous supprimez ${screenName} ?`}
                confirmBtnText="Oui"
                cancelBtnText="Annuler"
                onConfirm={() => {
                    removeScreen({screenId});
                    setShowModal(false);
                    hideRemoveOneScreenConfirmAlert();
                    // notify({ref: notificationAlertRef, type: "success", place: "br", message: `L'écran ${screenName} a été supprimé`});
                }}
                onCancel={hideRemoveOneScreenConfirmAlert}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                btnSize="md"
            />
        )

    }

    const hideRemoveOneScreenConfirmAlert = () => {
        setRemoveOneScreenConfirmAlertState(null);
    }


    return (
        <>
            <Modal isOpen={showModal} toggle={() => {
                setShowModal(!showModal);
            }} className="modal modal-black modal-content">
                <ModalHeader className="modal-header" toggle={() => setShowModal(!showModal)}>
                    <div>
                        <div><h4 className="modal-title">Ajouter un nouvel Ecran</h4></div>
                    </div>
                </ModalHeader>

                <ModalBody className="modal-body">
                    <Col md="12">
                        <Row>
                            <Col md="5">
                                <FormGroup >
                                    <label>Adresse Mac</label>
                                    <Input
                                        placeholder="Ex : a2:34:f6:78:90:00"
                                        type="text"
                                        id="macAddress"
                                        value={macAddress}
                                        onChange={(e) => setMacAddress(e.target.value)}
                                        disabled={screenModalAction === "update"}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="7">
                                <FormGroup>
                                    <label>Nom de l'écran</label>
                                    <Input
                                        type="text"
                                        id="screenName"
                                        value={screenName}
                                        onChange={(e) => setScreenName(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Groupe</label>
                                    <Input type="select" className="form-control" id="screenGroup" onChange={(e) => setScreenGroup(e.target.value)} style={{backgroundColor:"#1e1e2f"}}
                                           value ={screenGroup}>
                                        <option value=""></option>
                                        <OptionWidget groups={groups} />
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <FormGroup >
                                    <label>Longitude</label>
                                    <Input
                                        placeholder=""
                                        type="number"
                                        value={lon}
                                        onChange={e => setLon(e.target.value)}
                                        id="lon"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Latitude</label>
                                    <Input
                                        type="number"
                                        value={lat}
                                        onChange={e => setLat(e.target.value)}
                                        id="lat"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>

                <ModalFooter>
                    <button className="btn btn-danger" onClick={() => setShowModal(false)} >Fermer</button>
                    {
                        screenModalAction === "add"
                            ? (
                                <button className="btn btn-info" onClick={() => {
                                    if(validateScreen()) {
                                        addScreen();
                                        setShowModal(false);
                                        notify({ref: notificationAlertRef, type: "success", place: "br", message: `L'écran ${screenName} a été ajouté`});
                                    }

                                }}
                                        disabled={!(screenName && macAddress)}
                                >
                                    Enregistrer
                                </button>
                            ) : (
                                <button className="btn btn-info" onClick={() => {
                                    if(validateScreen()) {
                                        updateScreen(macAddress);
                                        setShowModal(false);
                                        notify({ref: notificationAlertRef, type: "success", place: "br", message: `L'écran ${screenName} a été modifié`});
                                    }

                                }}
                                        disabled={!(screenName && macAddress)}
                                >Modifier</button>
                            )
                    }

                </ModalFooter>
            </Modal>
            {removeOneScreenConfirmAlertState}
        </>
    )
});

function OptionWidget({
    groups
                      }) {
    return groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)
}
export default NewScreenModal;