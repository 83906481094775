import moment from "moment/moment";
import {Button, Table} from "reactstrap";
import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {removeUser} from "../../firebase/user";
import {notify} from "../../utils/notify";
import {useHistory} from "react-router-dom";

function UsersTable({
    users,
    notificationAlert,

                    }) {

    const [removeOneUserConfirmAlertState, setRemoveOneUserConfirmAlertState] = useState(null);

    const history = useHistory();
    const removeOneUserConfirmAlert = ({ uid, network, society }) => {
        setRemoveOneUserConfirmAlertState(
            <SweetAlert
                warning
                showCancel
                style={{ display: "block", marginTop: "-100px" }}
                title={`Voulez-vous supprimez le réseau ${society} ?`}
                confirmBtnText="Oui"
                cancelBtnText="Annuler"
                onConfirm={() => {
                    removeUser({uid, network});
                    hideRemoveOneUserConfirmAlert();
                    notify({
                        ref: notificationAlert,
                        type: "success",
                        place: "br",
                        message: `Le réseau ${society} a été supprimé`,
                    });
                }}
                onCancel={() => hideRemoveOneUserConfirmAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                btnSize="md"
            />
        );
    };

    const hideRemoveOneUserConfirmAlert = () => {
        setRemoveOneUserConfirmAlertState(null);
    };

    return(
        <>
            <Table className="tablesorter" responsive>
            <thead className="text-primary">
            <tr>
                <th>Nom(s) et Prénom(s)</th>
                <th>Sociétés</th>
                <th>Date</th>
                <th className="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            {
                users.map( user => {
                    return (
                        <tr key={user.uid}>
                            <td>{user.lastname} {user.firstname}</td>
                            <td>{user.society}</td>
                            <td>{user.currentDateTime ? moment(user.currentDateTime).format("DD MMM YYYY") : ""}</td>
                            <td className="text-center">
                                <a>
                                    <Button
                                        className="btn-icon btn btn-success"
                                        title="Modifier"
                                        color="link"
                                        onClick={() => history.push({pathname: `/admin/user/${user.uid}/edit`})}
                                    >
                                        <i className="tim-icons icon-pencil" />
                                    </Button>
                                    <Button className="btn-icon btn btn-danger"
                                            onClick={() => removeOneUserConfirmAlert({
                                                uid: user.uid,
                                                network: user.network,
                                                society: user.society
                                            }) }
                                            color="link">
                                        <i className="tim-icons icon-trash-simple" />
                                    </Button>
                                </a>
                            </td>
                        </tr>
                    )})
            }
            </tbody>
        </Table>
            {removeOneUserConfirmAlertState}
        </>
    )
}

export default UsersTable;