import React, {useEffect, useState} from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import {
  child,
  database,
  onValue,
  ref,
} from "../firebase/init-database.js";
import { auth } from "../firebase/init-auth.js";
import Moment from 'moment';
import 'moment/locale/fr';

// reactstrap components

const localizer = momentLocalizer(moment);

function Program() {
  
  const [events, setEvents] = useState([]);

  const selectedEvent = (event) => {
      alert(event.title);
  }
  
  const eventColors = (event) => {
      let backgroundColor = "event-";
      event.color 
          ? ( backgroundColor = backgroundColor + event.color)
          : ( backgroundColor = backgroundColor + "default");
      return {
          className: backgroundColor
      };
  }

  useEffect(() => {
      
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    
    onValue(usersRef, (snapshot) => {
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      const groupsRef = child(netRef, "/groups/");
      const playlistsRef = child(netRef, "/playlist/");

      onValue(playlistsRef, (snap) => {
        const result = snap.val();
        const newEvents = [];

       for (let key in result) {
           const newEvent = {
               title: "Playlist: "+ result[key].title,
               start: Moment(result[key].startDate).format('YYYY-MM-DDTkk:mm:ss'),
               end: result[key].endDate !== 0 ? Moment(result[key].endDate).format('YYYY-MM-DDTkk:mm:ss') : Moment(result[key].startDate).format('YYYY-MM-DDTkk:mm:ss'),
               color: "orange"
           }
           newEvents.push(newEvent);
        }
        setEvents((state) => [...state, ...newEvents])
      });

      onValue(groupsRef, (snap) => {
        const result = snap.val();
        let newEvents=[];

        for (let key in result) {
          const messagesRef = child(groupsRef, key + "/messages/");

          onValue(messagesRef, (snap) => {
            const result = snap.val();
            for (let key in result) {
                const newEvent = {
                    title: "Message : "+result[key].title,
                    start: Moment(result[key].startDate).format('YYYY-MM-DDTkk:mm:ss'),
                    end: result[key].endDate !== 0 ? Moment(result[key].endDate).format('YYYY-MM-DDTkk:mm:ss') : Moment(result[key].startDate).format('YYYY-MM-DDTkk:mm:ss'),
                    color: "green"
                }
                newEvents.push(newEvent);
            }
          });
        }

        console.log(newEvents);

        setEvents(state => [...state, ...newEvents])
        
      });
    });
  }, []);
  
  return (
    <>
        {console.table(events)}
        <div className="content">
            <Row>
                <Col md="12">                      
                      <Card className="">
                        <CardBody>
                            <BigCalendar
                                selectable
                                localizer={localizer}
                                events={events}
                                defaultView='month'
                                scrollToTime={new Date(1970, 1, 1, 6)}
                                defaultDate={new Date()}
                                onSelectEvent={event => selectedEvent(event)}
                                eventPropGetter={(event) => eventColors(event)}
                                messages={{
                                  next: "Suivant",
                                  previous: "Avant",
                                  today: "Aujourd'hui",
                                  month: "Mois",
                                  week: "Semaine",
                                  day: "Jour"
                                }}
                            />
                          </CardBody>
                      </Card>
                </Col>
            </Row>
        </div>
    </>
  );
}

export default Program;

