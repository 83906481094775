import React from "react";
import { database, onValue, push, ref, set } from "../firebase/init-database.js";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  Row,
  Modal,
  ModalBody, 
  ModalFooter,
  FormGroup,
  Form,
  Input,
  Col,
} from "reactstrap";


class Networks extends React.Component {
    constructor () {
    super();
    this.state = {
      showModal: false,
      libelle: '',
      listReseau : []
      
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    
    this.handleChange = this.handleChange.bind(this);
    this.addNetwork   = this.addNetwork.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  } 

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  addNetwork = event =>{
    const reseauRef = ref(database, "reseau/");
    const newReseauRef = push(reseauRef);
    set(newReseauRef, {
      libelle:this.state.libelle}).then(_ => {
    });
  }

  componentDidMount() {
    const reseauRef = ref(database, "reseau");
    onValue(reseauRef, snapshot => {
      let reseaulist = [];
      snapshot.forEach(snap => {
          // snap.val() is the dictionary with all your keys/values from the 'students-list' path
          reseaulist.push({key:snap.key,libelle:snap.val().libelle});
      });
      this.setState({ listReseau: reseaulist });
    });    
  }

  

  render() {
    const modalStyle = {
      overlay: {
        position: "fixed",
        top: "10px",
        width: "700px",
        left: "350px",
        right: "5px",
        bottom: "5px"
      }
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
            <h5 className="title">Réseaux</h5>
                <p className="category">
                    Tous les réseaux
                </p>

            <Row>
            {this.state.listReseau.map( data => {
                    return (
              <Col lg="3">
                <NavLink to={{
                    pathname: '/admin/groups',
                    state :{
                      networkKey: data.key,
                      networkName: data.libelle
                    }
                  }}
                  >  
                  <Card className="card-chart">
                    <CardHeader>
                      <h5 className="card-category">763 Groupes</h5>
                      <CardTitle tag="h3">
                        <i className="tim-icons icon-vector text-info" />{" "}
                        {data.libelle}
                        <hr/>
                        <Row>
                          <Col lg="6">
                          </Col>
                          <Col lg="6">
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                  </Card>
              </NavLink>
              </Col>
              )})}
              
            </Row>
            </Col>
              
            
          </Row>
          <Row>
            <Col md="12">
              <a href="#" style={{alignSelf: 'flex-end',position: 'absolute',top:180, right:0}}>
                <button className="btn-icon btn-round btn tim-icons icon-simple-add text-info" onClick={this.handleOpenModal} />
              </a>
            </Col>
            <Modal isOpen={this.state.showModal} style={modalStyle}>
              <div className="text-center">
                 <div><b>Enregistrement d'un réseau</b></div>
              </div>
              <ModalBody>
                <Form  onSubmit={this.addNetwork}>                
                <div className="text-left" style={{marginLeft:"15px"}}>
                <Col className="pr-md-1" md="8">
                        <FormGroup>
                          <label><span className="text-right"><b>Nom du réseau</b></span></label>
                          <Input
                            id="libelle"
                            placeholder="Nom du réseau"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.libelle}
                          />
                        </FormGroup>
                      </Col>
                </div>
                </Form>

              </ModalBody>
              <ModalFooter>
                <button className="btn btn-danger" onClick={this.handleCloseModal} >Annuler</button>
                <button className="btn btn-info" onClick={this.addNetwork}>Enregistrer</button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </>
    );
  }
}

export default Networks;
