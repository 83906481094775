function groupBy(tableauObjets, propriete){
    let _groups=[];
    let groups = Object.values(tableauObjets.reduce(function (acc, obj) {
      var cle = obj[propriete];
      if(!acc[cle]){
        acc[cle] = [];
      }
      acc[cle].push(obj);
      return acc;
    }, {}));

    groups.forEach(group=>{
      _groups.push({
        title: group[0][propriete],
        checked:false,
        data:group
      })
    });

    return _groups;
  }

  export { groupBy };