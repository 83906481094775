import {Button} from "reactstrap";
import Files from "react-files";
import React from "react";
import {fileToVideo} from "../utils/imageToVideo";
import {fileName, fileSizeReadable} from "../utils/file";

function FilesFromDevice({
    setLoader,
    files,
    setFiles,
    children
                       }) {
    const handleFilesChangeFromDevice = async (selectedFiles) => {
        let nFiles = [];
        setLoader(true);
        for (const file of selectedFiles) {
            if(file.preview.type === "image") {
                const video = await fileToVideo(file);
                const nFile = new File([video], fileName(file.name), {lastModified: file.lastModified, type: "video/mp4"});
                nFile.id = file.id;
                nFile.extension = "mp4";
                nFile.sizeReadable = fileSizeReadable(nFile.size);
                nFile.preview = {
                    type: 'file'
                };
                nFiles.push(nFile);
            } else {
                nFiles.push(file);
            }
        }
        setLoader(false);
        setFiles([...nFiles, ...files]);
    }

    return(
        <Files
            className="files-dropzone"
            onChange={handleFilesChangeFromDevice}
            accepts={['image/*', 'video/*']}
            multiple
            maxFileSize={25_000_000}
            minFileSize={0}
            clickable
        >
            {
                React.isValidElement(children) ? (
                    children
                ) : (
                    <Button
                        title="Depuis l'appareil"
                        outline className="btn-icon btn btn-info w-100"
                    >
                        Parcourir
                    </Button>
                )
            }

        </Files>
    );
}

export default FilesFromDevice;