import React, {createRef, useEffect} from 'react'
import {Button, Col, Row} from 'reactstrap';
import FilesFromDevice from "../FilesFromDevice";
import SelectTreeFolders from "./SelectTreeFolders";
import SelectedVideosFilesList from "./SelectedVideosFilesList";


function SelectedVideoPreview({
    videos = [],
    setVideos,
    files = [],
    videosFolders,
    setVideosFolders,
    setFiles,
    setSelectedVideoToPreview,
    loader,
    setLoader,
    setSelectTreeFoldersRef
}) {

    const selectTreeFolderRef = createRef();

    useEffect(() => {
       setSelectTreeFoldersRef(selectTreeFolderRef);
    }, [selectTreeFolderRef]);

    return (
        <Row>
            <Col>
                <Row className="d-flex align-items-center">
                    <Col xs={6} md={8}>
                        <SelectTreeFolders
                            ref={selectTreeFolderRef}
                            videos={videos} setVideos={setVideos}
                            setFiles={setFiles}
                            videosFolders={videosFolders}
                            setVideosFolders={setVideosFolders}
                        />
                    </Col>
                    <Col>
                        <FilesFromDevice files={files} setFiles={setFiles} setLoader={setLoader} />
                    </Col>
                    <Col>
                        <Button
                            title="Tout Effacer"
                            className="btn-icon btn btn-danger w-100"
                            onClick={() => selectTreeFolderRef.current?.handleResetVideosAndFiles()}
                            disabled={videos.length < 1 && files.length < 1}
                        >
                            Tout Effacer
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <SelectedVideosFilesList
                        files={files} setFiles={setFiles}
                        videos={videos}
                        setSelectedVideoToPreview={setSelectedVideoToPreview}
                        loader={loader}
                        selectTreeFolderRef={selectTreeFolderRef}
                    />
                </Row>
            </Col>
        </Row>
    )
}

export default SelectedVideoPreview;