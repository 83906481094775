import { initializeApp } from 'firebase/app';
import config from "../config/default.js"
import { 
    getAuth,
    setPersistence, browserSessionPersistence, 
    inMemoryPersistence, signInWithEmailAndPassword, 
    signInWithPopup, GoogleAuthProvider,
    onAuthStateChanged, signOut, 
    createUserWithEmailAndPassword,
    deleteUser,
} from "firebase/auth";

const app = initializeApp(config.firebase);
const auth = getAuth(app);

export { 
    auth, setPersistence, deleteUser,
    browserSessionPersistence, signInWithEmailAndPassword, 
    signInWithPopup, GoogleAuthProvider, inMemoryPersistence, 
    onAuthStateChanged, signOut, createUserWithEmailAndPassword 
};