class ImagesToVideo {
  canvas = null
  ctx = null
  mediaRecord = null
  chunks = new Set()
  timer = null

  option = {
    intervals: 100,
    drawIntervals: 1000,
    fileList: [],
    fileDownload: { 
      fileType: `mp4`,
      fileName: `video`
    }
  }


  constructor(canvas, option = {}) {
    this.canvas = canvas
    this.ctx = canvas.getContext('2d')
    this.option = { ...this.option, ...option }
    this.initMedia()
  }

  initMedia() {
    const mediaStream = this.canvas.captureStream(48)
    this.mediaRecord = new MediaRecorder(mediaStream, {
      videoBitsPerSecond: 8500000
    })

    this.mediaRecord.ondataavailable = (e) => {
      this.chunks.add(e.data)
    }
  }

  async drawImage(file) {
    try {
      const src = Object.prototype.toString.call(file) === '[object File]' ?
                  URL.createObjectURL(file) : file.src
      await new Promise((resolve) => {
        const img = new Image()
        img.onload = () => {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)
        }
        img.src = src
      })
    } catch (error) {
      console.log('drawImage error', error)
    }
  }

  async startRecord() {
    this.mediaRecord && this.mediaRecord.start(this.option.intervals || 100)
    let index = 0
    this.timer && clearInterval(this.timer)
    return await new Promise((resolve) => {
      this.timer = setInterval(() => {
        const file = this.option.fileList[index] || null
        file ? (this.drawImage(file) && (index += 1)) : resolve(this.stopRecord())
      }, this.option.drawIntervals || 1000)
    })
  }

  /**
   * @returns Object {name, blob, src}
  */
  stopRecord() {
    this.timer && clearInterval(this.timer)
    this.mediaRecord && this.mediaRecord.stop()
    const type = `${this.option.fileDownload.fileType || 'mp4'}`
    const blob = new Blob(this.chunks, {
      type: `video/${type}`
    })
    return blob
  }
}

const startRecord = (canvas, fileList) => {
  try {
    const intance = new ImagesToVideo(canvas, { fileList })
    return intance.startRecord()
  } catch (error) {
    console.log('startRecord error', error)
  }
  return null
}

export const fileToVideo = async (file) => {
    const files = [];
    for (let i = 0; i < 10; i++) {
        files.push(file);        
    }

    if(!files || files.length === 0) return;
          
    const fileList = Object.keys(files).map(key => {
      const file = files[key]
      return {
        file,
        name: file.name,
        src: URL.createObjectURL(file)
      }
    })
  const canvas = document.createElement('canvas');
  canvas.width = 1224;
    canvas.height = 768;
    canvas.style.zIndex = 8;
    canvas.style.position = "absolute";
    return await startRecord(canvas, fileList);
}