import DropdownTreeSelect from "react-dropdown-tree-select";
import {FormGroup} from "reactstrap";
import React, {forwardRef, useImperativeHandle, useRef} from "react";

const SelectTreeFolders = forwardRef(({
    videos,
    setVideos,
    videosFolders,
    setFiles,
    setVideosFolders
    }, ref) => {

    useImperativeHandle(ref, () => {
        return {
            getVideoParent(currentNode) {
                return getVideoParent(currentNode);
            },

            handleRemoveVideo(video) {
                handleRemoveVideo(video);
            },

            handleResetVideosAndFiles() {
                handleResetVideosAndFiles();
            },

            toggleCheckedVideo(nodeFolder, currentNodeVideo, checked) {
                toggleCheckedVideo(nodeFolder, currentNodeVideo, checked);
            },
            allFolderVideosSelected(videoFolder) {
                allFolderVideosSelected(videoFolder);
            },

            toggleCheckedFolder(videoFolder, checked) {
                toggleCheckedFolder(videoFolder, checked);
            },

            handleSetVideo(videos) {
                handleSetVideo(videos);
            }
        };
    }, []);

    const dropdownTreeSelectRef = useRef(null);
    const videoAlreadySet = (newVideo) => videos.some(video => video.value === newVideo.value && video.parentId === newVideo.parentId);
    const handleSetVideos = (newVideos = []) =>
        newVideos.forEach(video => handleSetVideo(video));

    const handleSetVideo = (newVideo) => {
        const newVideos = videos;
        if(!videoAlreadySet(newVideo)) {
            newVideo.checked = true;
            newVideos.push(newVideo);
            setVideos([...newVideos]);
        }
    }

    const getVideoParent = (currentNode) => videosFolders.filter(folder => folder.id === currentNode.parentId)[0];

    const toggleCheckedVideo = (nodeFolder, currentNodeVideo, checked) => {
        const currentNodeFolder = videosFolders.filter(folder => folder.value === nodeFolder.value)[0];
        const videos = currentNodeFolder.children;
        const currentVideo = videos.filter(video => video.value === currentNodeVideo.value)[0];
        currentVideo.checked = checked;
    }

    const toggleCheckedFolder = (currentNodeFolder, checkedState = false) => {
        const currentFolder = changeFolderCheckState(currentNodeFolder, checkedState);
        currentFolder.children.forEach(video => {
            toggleCheckedVideo(currentFolder, video, checkedState);
        });
    }

    const handleRemoveVideo = (videoToRemove) => {
        const newVideos = videos.filter((video) => !(video.value === videoToRemove.value && video.parentId === videoToRemove.parentId));
        toggleCheckedVideo(getVideoParent(videoToRemove), videoToRemove, false);
        changeFolderCheckState(getVideoParent(videoToRemove), false);
        setVideos([...newVideos]);
    }

    const handleResetVideosAndFiles = () => {
        handleResetVideos();
        handleFilesRemoveAllFromDevice();
    }

    const allFolderVideosSelected = (nodeFolder = []) =>
        nodeFolder.children.every(video => video.checked);

    const allVideosFoldersSelected = () => videosFolders.every(folder => folder.checked);

    const handleFilesRemoveAllFromDevice = () => setFiles([]);

    const removeVideos = (removedVideos = []) => {
        const newVideos = videos.filter(stateVideo  => !(removedVideos.some(video => video.value === stateVideo.value && video.parentId === stateVideo.parentId)));
        setVideos([...newVideos]);
    }


    const isFolderSelected = (currentNode) => currentNode._depth === 0 && !currentNode.children;

    const isVideoSelected = (currentNode) => currentNode._depth === 1 && currentNode.parentId;

    const isNodeCheckedOrNot = (currentNode) => currentNode.checked;

    const selectVideosFolder = (currentFolderNode) => {
        const newVideos = [];
        const selectedNode = videosFolders.filter(folder => folder.label === currentFolderNode.label)[0];
        selectedNode?.children.forEach(node => newVideos.push(node));
        return newVideos;
    }
    const handleSelectAllVideos = () => {
        const currVideosFolders = videosFolders;
        currVideosFolders.forEach((folder) => {
            toggleCheckedFolder(folder, true)
            if(folder.children.length > 0) handleSetVideos(folder.children);
        });
        selectVideosFolder([...currVideosFolders]);
    }

    const handleResetVideos = () => {
        const currVideosFolders = videosFolders;
        currVideosFolders.forEach(folder => toggleCheckedFolder(folder, false));
        selectVideosFolder([...currVideosFolders]);
        setVideos([]);
    }

    const changeFolderCheckState = (currentNodeFolder, checkedState) => {
        let newVideosFolders = videosFolders;
        const currentFolder = newVideosFolders.filter(folder => folder.label === currentNodeFolder.label)[0];
        currentFolder.checked = checkedState;
        setVideosFolders([...newVideosFolders]);

        return currentFolder;
    }

    const handleChangeSelectTree = (currentNode) => {
        if(currentNode.value === 'selectAll') {
            if (currentNode.checked)
                handleSelectAllVideos();
            else
                handleResetVideos();
        } else {
            if(isFolderSelected(currentNode)) {
                const newVideos = selectVideosFolder(currentNode);
                const checkedOrNotFolder = isNodeCheckedOrNot(currentNode);
                if(checkedOrNotFolder) {
                    handleSetVideos(newVideos);
                } else {
                    removeVideos(newVideos);
                }
                toggleCheckedFolder(currentNode, checkedOrNotFolder);
            }

            if(isVideoSelected(currentNode)) {
                const video = currentNode;
                const videoFolder = getVideoParent(currentNode);
                const checkedOrNotVideo = isNodeCheckedOrNot(video);
                if(checkedOrNotVideo) {
                    handleSetVideo(video);
                } else {
                    handleRemoveVideo(video);
                }
                toggleCheckedVideo(videoFolder, currentNode, checkedOrNotVideo);

                changeFolderCheckState(videoFolder, !!allFolderVideosSelected(videoFolder));

            }

            if(videosFolders[0].value === 'selectAll')
                changeFolderCheckState(videosFolders[0], !!allVideosFoldersSelected())
        }
    }

    return(
        <FormGroup>
            <label>Videos</label>
            <DropdownTreeSelect
                ref={dropdownTreeSelectRef}
                data={videosFolders}
                onChange={handleChangeSelectTree}
                onAction={() => null}
                texts={{ placeholder: "Ajouter" }}
                className="tag-tree"
            />
        </FormGroup>
    );
});

export default SelectTreeFolders;