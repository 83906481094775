import {auth} from "./init-auth";
import {database, onValue, push, ref, remove} from "./init-database";
import {deleteObject, getDownloadURL, listAll, refFromStorage, storage, uploadBytes} from "./init-storage";
import {notify} from "../utils/notify";

export const addFolder = ({
    folder,
    setFolder
                   }) =>{
    const user=auth.currentUser;
    if(folder !== "" && folder != null && folder !== " ") {
        const usersRef = ref(database, 'users/'+ user.uid);
        onValue(usersRef,
            (snapshot) => {
                const netRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/')
                push(netRef, {
                    label: folder,
                    timestamp: Date.now()}).then(_ => {
                    setFolder("")
                });
            }, {
                onlyOnce: true
            })
    }

}

export const removeFolder = ({
    folder
}) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            // Delete videos files in firebase storage
            const videosRef = refFromStorage(storage, snapshot.val()?.network + "/" + folder.label);

            // Delete Folder in Realtime Database
            const folderRef = ref(database, 'networks/' + snapshot.val()?.network + '/folders/' + folder.id);
            remove(folderRef);

            // Find all the prefixes and items.
            if(videosRef)
                listAll(videosRef)
                    .then((res) => {
                        res.items.forEach((itemRef) => {
                            // All the items under listRef.
                            deleteObject(itemRef)
                        });
                    });
        }, {
            onlyOnce: true
        })
}

export const addVideos = ({
    files,
    folders,
    fileFolderId,
                          }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    const folderId = fileFolderId;
    if(folderId &&  files.length > 0) {
        onValue(usersRef,
            (snapshot) => {

                const folderRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + folderId +"/videos");

                files.forEach(file => {
                    const videoRef = refFromStorage(storage, snapshot.val()?.network + '/' + folders.filter(folder=> folder.id === folderId )[0].label+'/' + file.name);

                    const metadata = {
                        contentType: file.type,
                        size: file.sizeReadable
                    };
                    uploadBytes(videoRef, file, metadata).then(_ => {
                        getDownloadURL(videoRef).then(url => {
                            push(folderRef,  {
                                url: url,
                                label:file.name,
                                size: file.sizeReadable
                            })
                        })
                    })

                })

            }, {
                onlyOnce: true
            })

    }

}

export const removeVideo = ({
    folder,
    videoId,
    videoLabel,
}) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            // Delete video file in firebase storage
            const videoRefStorage = refFromStorage(storage, snapshot.val()?.network + "/" + folder.label + "/" + videoLabel);
            const videoRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + folder.id + "/videos/" + videoId);

            remove(videoRef);

            // Delete the file
            deleteObject(videoRefStorage)
        }, {
            onlyOnce: true
        })
}

export const removeAllFolders = () => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            const folderRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/')
            remove(folderRef);
        }, {
            onlyOnce: true
        })
}

export const removeSelectedFolders = ({
    checkedFolders,
    toggleCheckedAllFolders,
    setToggleCheckedAllFolders,
    notificationAlert
                                      }) => {
    if(toggleCheckedAllFolders) {
        removeAllFolders();
        setToggleCheckedAllFolders(false);

        notify({ref: notificationAlert, type: "success", place: "br", message: `${checkedFolders().length} dossiers supprimés`});
        return ;
    }

    if(checkedFolders().length > 0) {
        const folders = checkedFolders();
        folders.forEach(folder => removeFolder({folder}))
        notify({ref: notificationAlert, type: "success", place: "br", message: `${checkedFolders().length} dossiers supprimés`});
    }

}