import React, {createRef, useEffect, useRef, useState} from "react";
import { database, onValue, ref, child,} from "../firebase/init-database.js";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

import {
  Card,
  Col,
} from "reactstrap";
import "bootstrap/js/src/collapse.js";
import { auth } from "../firebase/init-auth.js";
import { convertSizeFileToByte } from "utils/convertion.js";
import { convertByteToHumanRedeableSize } from "utils/convertion.js";
import NewVideoModal from "../components/Storage/NewVideoModal";
import StorageFooterCard from "../components/Storage/StorageFooterCard";
import StorageHeaderCard from "../components/Storage/StorageHeaderCard";
import StorageBodyCard from "../components/Storage/StorageBodyCard";


function Storage () {

  const [folders, setFolders] = useState([]);
  const [folder, setFolder] = useState("")
  const [stockageSize, setStockageSize] = useState(0);

  const notificationAlert = useRef(null);
  const newScreenModalRef = createRef();
  let cardBodyRef = createRef();

  const checkedFolders = () => folders.filter(folder => folder.checked);

  useEffect(() => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
      (snapshot) => {
        const netRef= ref(database, 'networks/' + snapshot.val()?.network);
        const foldersRef = child(netRef, '/folders/')
        onValue(foldersRef,
          (snap) => {
            const result=snap.val();
            const list=[];
            let stockageSize = 0;
            for (let key in result) {
                if(result[key].label !== null){
                  const date=new Date(result[key].timestamp);
                  let folderSize = 0;
                  const videos = [];
                  if(result[key]?.videos) {                  
                    const videoRef = ref(database, 'networks/'+ snapshot.val()?.network + '/folders/' + key + "/videos");
                    
                    onValue(videoRef, snapshot => {
                      const video = snapshot.val();
                        for (let key in video) {
                            const videoSize = convertSizeFileToByte(video[key]?.size);
                        folderSize += videoSize;
                        videos.push({
                          id: key,
                          label: video[key].label,
                          size: video[key].size,
                          url: video[key].url,
                        })
                      }
                    })  

                    stockageSize +=folderSize;

                    folderSize = convertByteToHumanRedeableSize(folderSize);    
                  }
                  // Get numbers of media in one folder
                  let count=0;
                  if(result[key].videos !== undefined) {
                    count=Object.values(result[key].videos).length; 
                  }                    

                  list.push({
                    label: result[key].label,
                    count: count,
                    id: key,
                    size: folderSize,
                    checked: false,
                    videos: videos,
                    date: (date.getDate()<10?("0"+date.getDate()):date.getDate())+
                    "/"+(date.getMonth()<9?("0"+(date.getMonth()+1)):(date.getMonth()+1))+
                    "/"+date.getFullYear()+
                    " "+(date.getHours()<10?("0"+date.getHours()):date.getHours())+
                    ":"+(date.getMinutes()<10?("0"+date.getMinutes()):date.getMinutes())+
                    ":"+(date.getSeconds()<10?("0"+date.getSeconds()):date.getSeconds()),
                  })
                }
            }
            setFolders(list);
            setStockageSize(stockageSize)
          }
        );
      }, 
      {
        onlyOnce: true
      }
    );
    
   }, []);

    return (
      <>
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notificationAlert} />
          </div>
          <Col md="12">
            <Card>
              <StorageHeaderCard
                  newScreenModalRef={newScreenModalRef}
                  checkedFolders={checkedFolders}
                  notificationAlertRef={notificationAlert}
                  setToggleCheckedAllFolders={cardBodyRef?.current?.setToggleCheckedAllFolders}
                  toggleCheckedAllFolders={cardBodyRef?.current?.toggleCheckedAllFolders}
              />
              <StorageBodyCard
                  ref={cardBodyRef}
                  checkedFolders={checkedFolders}
                  folders={folders}
                  setFolders={folders => setFolders(folders)}
                  notificationAlertRef={notificationAlert}
              />
              <StorageFooterCard
                  folder={folder}
                  setFolder={folder => setFolder(folder)}
                  stockageSize={stockageSize}
                  notificationAlertRef={notificationAlert}
              />
            </Card>
          </Col>
          <NewVideoModal
              folders={folders}
              ref={newScreenModalRef}
              notificationAlertRef={notificationAlert}
          />
        </div>
     </>
    );
}


export default Storage;