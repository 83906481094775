import React from "react";
import { database, push, ref, set } from "../firebase/init-database.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

class AddTools extends React.Component {
  constructor () {
    super();
    this.state = {
      showOption: {},
      materiel: "",
      quantity: "",
      etatmat: "",
      locatempl: "",
      addressmac: "",
      sim: "",
      mark: "",
      ram: "",
      memory: "",
      versionsys: "",
      system: "",
      pouce: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.addTool   = this.addTool.bind(this);

  }
  
  handleOptionChange = (field, event) => {
     event.preventDefault();
     let showOption = this.state.showOption;
     showOption[field] = event.target.value;

    //  console.log('okkk');

     if (field === "materiel"){
       const tableValue = showOption["materiel"];
       this.setState({showOption});
     }
    //  console.log(this.state.showOption.materiel)
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  addTool = event =>{
    const materielRef = ref(database, 'materiel');
    const newMaterielRef = push(materielRef);

    set(newMaterielRef, {
      materiel:this.state.materiel, quantity:this.state.quantity, etatmat:this.state.etatmat,
      locatempl:this.state.locatempl, addressmac:this.state.addressmac, sim:this.state.sim,
      mark:this.state.mark, ram:this.state.ram, memory:this.state.memory, versionsys:this.state.versionsys,
      system:this.state.system, pouce:this.state.pouce}).then(_ => {
    }); 
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Ajout d'un materiel</h5>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.addTool}>
                  <Row>
                      <Col className="pr-md-1" md="4">
                        <FormGroup>
                          <label>Libellé du matériel</label>
                          <select className="form-control" id="materiel" name="materiel" 
                              value={this.state.showOption.materiel} 
                              onChange={this.handleOptionChange.bind(this,"materiel")}>
                              <option >Sélectionner ici</option>
                              <option value="A">Box Internet</option>
                              <option value="B">Box ANdroid</option>
                              <option value="C">Ecran</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Quantité(s)</label>
                          <Input
                            id="quantity"
                            placeholder="Quantité"
                            type="number"
                            onChange={this.handleChange}
                            value ={this.state.quantity}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>Etat du matériel</label>
                          <select name="" id="etatmat" className="form-control"   onChange={this.handleChange}
                            value ={this.state.etatmat}>
                            <option value="0">Sélectionner ici</option>
                            <option value="1">Neuf</option>
                            <option value="2">En bonne état</option>
                            <option value="3">En Panne</option>
                            <option value="4">Ne fonctionne pas</option>
                            <option value="5">Anomalie</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="8">
                        <FormGroup>
                          <label>Emplacement du matériel</label>
                          <Input
                            id="locatempl"
                            placeholder="Emplacement du matériel"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.locatempl}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {this.state.showOption.materiel === "A" &&
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label>Adresse MAC</label>
                          <Input
                            id="addressmac"
                            placeholder="Adresse MAC"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.addressmac}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Numéro de téléphone de la SIM</label>
                          <Input
                            id="sim"
                            placeholder="Numéro de téléphone de la SIM"
                            type="number"
                            onChange={this.handleChange}
                            value ={this.state.sim}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Marque</label>
                          <Input
                            id="mark"
                            placeholder="Marque"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.mark}
                          />
                        </FormGroup>
                      </Col>
                    </Row> }
                   
                    {this.state.showOption.materiel === "B" &&
                    <Row >
                      <Col md="3">
                        <FormGroup>
                          <label>RAM</label>
                          <Input
                            id="ram"
                            placeholder="RAM"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.ram}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Mémoire</label>
                          <Input
                            id="memory"
                            placeholder="Mémoire"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.memory}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Version du système</label>
                          <Input
                            id="versionsys"
                            placeholder="Version du système"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.versionsys}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Marque</label>
                          <Input
                            id="mark"
                            placeholder="Marque"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.mark}
                          />
                        </FormGroup>
                      </Col>
                    </Row>}
                    
                    {this.state.showOption.materiel === "C" &&
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <label>Système</label>
                          <Input
                            id="system"
                            placeholder="Système"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.system}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Marque</label>
                          <Input
                            id="mark"
                            placeholder="Marque"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.mark}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Version du système</label>
                          <Input
                            id="versionsys"
                            placeholder="Version du système"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.versionsys}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Mémoire</label>
                          <Input
                            id="memory"
                            placeholder="Mémoire"
                            type="text"
                            onChange={this.handleChange}
                            value ={this.state.memory}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <label>Nombre de pouces</label>
                          <Input
                            id="pouce"
                            placeholder="Nombre de pouces"
                            type="number"
                            onChange={this.handleChange}
                            value ={this.state.pouce}
                          />
                        </FormGroup>
                      </Col>
                    </Row>}
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit" onClick={this.addTool}>
                    Valider
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AddTools;
