import {
    Button,
    Card, CardBody,
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import NoVideo from "../../assets/img/no-video.png";
import parse from "html-react-parser";
import React from "react";

function ScreenCard({
    screenModalRef,
    screens = [],
    videos = [],
    messages = []
                    }) {
    return screens.map(screen => (
        <Col md={4} xl={3} key={screen.id} className="d-flex align-items-stretch">
            <Card className="card-chart" >
                <ScreenCardHeader
                    screenModalRef={screenModalRef}
                    screen={screen}
                    videos={videos}
                    messages={messages}
                />
                <ScreenCardBody messages={messages} screen={screen} />

            </Card>
        </Col>
    ))
}

function ScreenCardBody ({
    messages,
    screen,
}) {
    return (
        <CardBody>
            <div className="mt-4 mb-1 px-1 d-flex justify-content-between align-items-start text-nowrap">
                {
                    messages ?
                        messages.length > 0
                            ? <span className={screen.online?"online pr-1":"offline pr-1"}><i className="tim-icons icon-chat-33 mr-1"  title="messages actifs"/>{messages.length}</span>
                            : <span className="pr-1 text-gray"><i className="tim-icons icon-chat-33 mr-1"  title="messages actifs"/>0</span>
                        : <span className="pr-1"><i className="tim-icons icon-chat-33 mr-1"  title="messages actifs"/>0</span>
                }
                <span>{screen.address}</span>
            </div>
            <div className="card-bottom">
                <Button className="btn-icon btn">
                    <i className="tim-icons icon-button-power" title="allumer/éteindre"/>
                </Button>
                <i className="tim-icons icon-button-pause" title="allumer/éteindre"/>
                <h5 className={screen.online ? "online" : "offline"} style={{margin:'auto 0'}}>{screen.online ? "En ligne" : "Hors ligne"} &#11044;</h5>
            </div>
        </CardBody>
    )
}
function ScreenCardHeader({
    screenModalRef,
    videos,
    messages,
    screen
}) {
    return (
        <>
            <CardHeader>
                <CardTitle tag="span">
                    <b className="text-nowrap">{screen.name}</b>
                </CardTitle>
                <UncontrolledDropdown>
                    <DropdownToggle
                        caret
                        className="btn-icon"
                        color="link"
                        data-toggle="dropdown"
                        type="button"
                    >
                        <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                        <DropdownItem
                            href="#pablo"
                            onClick={(e) => screenModalRef?.current.handleOpenModal({action: "update", screenId: screen.address})}
                        >
                            Modifier
                            <i className="ml-1 text-success tim-icons icon-pencil" />
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={() => screenModalRef?.current.removeOneScreenConfirmAlert({screenId: screen.address, screenName: screen.name})}
                        >
                            Supprimer
                            <i className="ml-1 text-danger tim-icons icon-trash-simple" />
                        </DropdownItem>
                        <DropdownItem
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                        >
                            Ajouter rapport
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </CardHeader>
            {
                (videos && videos.length > 0)  && (videos.filter(video => video.groupId === screen.screenGroup)[0])
                    ? (
                        <video
                            width="100%"
                            className="mt-4 ml-2 mr-2"
                            src={videos.filter(video => video.groupId === screen.screenGroup)[0].src}
                            controls
                        ></video>
                    )
                    : <img src={NoVideo} alt="" className="mt-4 ml-2 mr-2"  height={90} title="Aucune video" />
            }
            <marquee className="text-white ml-2 mr-2 marquee">
                {
                    messages ? messages.map(message => parse(message.content)) : null
                }
            </marquee>
        </>
    );
}

export default ScreenCard;