import {Button, Col, Row} from "reactstrap";
import React from "react";

function SelectedVideosFilesList({
    videos,
    files,
    setFiles,
    loader,
    setSelectedVideoToPreview,
    selectTreeFolderRef
                                 }) {

    const setVideoToPreview = (url) => (event) => {
        event.preventDefault();
        setSelectedVideoToPreview(url);
    }
    const handleFilesRemoveOneFromDevice = ({fileId = ""}) => {
        const newFiles = files.filter(file => file.id !== fileId);
        setFiles([...newFiles]);
    }

    return (
        <>
            {loader ?
                (
                    <div className="loading-spinner mt-4 mb-4"></div>
                ) : (
                    (videos && videos.length > 0)|| (files && files.length > 0) ? (
                        <Col>
                            <Row>
                                {videos.map((video) => {
                                    return (
                                        <Col md="12" key={video.id + video.value}>
                                            <Row>
                                                <Col>
                                                    <a href="#" onClick={setVideoToPreview(video.value)}                                      >
                                                        <video
                                                            width="80" height="50"
                                                            key={video.id}
                                                        >
                                                            <source src={video.value} type="video/mp4" />
                                                            <source src={video.value} type="video/webm" />
                                                            <source src={video.value} type="video/ogg" />
                                                            <source src={video.value} type="video/avi" />
                                                            <source src={video.value} type="video/mpeg" />
                                                        </video>
                                                    </a>
                                                </Col>

                                                <Col md="9" className="uploadVideoText">
                                                    <Row>
                                                        <b>{video.label}</b>
                                                    </Row>
                                                    <Row>
                                                        <p>{video.size}</p>
                                                    </Row>
                                                </Col>

                                                <Col md="1">
                                                    <Button
                                                        className="btn-link btn-icon btn-round"
                                                        color="primary"
                                                        onClick={() => selectTreeFolderRef.current?.handleRemoveVideo(video) }
                                                    >
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                            </Row>
                            <Row>
                                {files.map((file) => {
                                    return (
                                        <Col md="12" key={file.id + window.URL.createObjectURL(file)}>
                                            <Row className="mt-2">
                                                <Col>
                                                    <a href="#" onClick={setVideoToPreview(window.URL.createObjectURL(file))}                                      >
                                                        <video
                                                            width="80" height="50"
                                                            key={file.id}
                                                        >
                                                            <source src={window.URL.createObjectURL(file)} type="video/webm" />
                                                            <source src={window.URL.createObjectURL(file)} type="video/ogg" />
                                                            <source src={window.URL.createObjectURL(file)} type="video/avi" />
                                                            <source src={window.URL.createObjectURL(file)} type="video/mpeg" />
                                                        </video>
                                                    </a>
                                                </Col>

                                                <Col md="9" className="uploadVideoText">
                                                    <Row left>
                                                        <b>{file.name}</b>
                                                    </Row>
                                                    <Row>
                                                        <p>{file.sizeReadable}</p>
                                                    </Row>
                                                </Col>

                                                <Col md="1">
                                                    <Button className="btn-link btn-icon btn-round" color="primary" onClick={() => handleFilesRemoveOneFromDevice({fileId: file.id})}>
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                </Col>
                                            </Row>

                                        </Col>
                                    );
                                })}
                            </Row>
                        </Col>

                    ) : (
                        <div className="pt-4 pb-4 text-center w-100">
                            Aucune video selectionnée
                        </div>
                    )
                )}
        </>
    );
}

export default SelectedVideosFilesList;