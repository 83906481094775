import {Button} from "reactstrap";
import React from "react";

function GroupRow({
    groups,
    networkId,
    setGroup,
    setGroupId,
    setGroupAction,
    removeOneGroupConfirmAlert,
                  }) {

    return groups.map(group => (
        <tr key={group.id}>
            <td>
                {group.name}</td>
            <td className="text-center">
                {group.screens_nb}
                {group.screens_nb > 1 ? " écrans" : " écran"}
            </td>
            <td className="text-center">
                {
                    group.id !== networkId ? (
                            <a>
                                <Button className="btn-icon btn btn-danger"
                                        color="link" title="Supprimer définitivement"
                                        onClick={() => removeOneGroupConfirmAlert({
                                            groupId: group.id,
                                            groupLabel: group.name
                                        })}
                                >
                                    <i className="tim-icons icon-trash-simple"/>
                                </Button>
                                <Button
                                    className="btn-icon btn btn-success"
                                    title="Modifier"
                                    color="link"
                                    onClick={() => {
                                        setGroup(group.name)
                                        setGroupId(group.id)
                                        setGroupAction("update")
                                    }}

                                >
                                    <i className="tim-icons icon-pencil"/>
                                </Button>
                            </a>
                        )
                        : (
                            <a>
                                <Button className="btn-icon btn btn-danger"
                                        color="link" title="Supprimer définitivement"
                                        disabled
                                >
                                    <i className="tim-icons icon-trash-simple"/>
                                </Button>
                                <Button
                                    className="btn-icon btn btn-success"
                                    title="Modifier"
                                    color="link"
                                    disabled
                                >
                                    <i className="tim-icons icon-pencil"/>
                                </Button>
                            </a>
                        )
                }

            </td>
        </tr>
    ))
}

export default GroupRow;