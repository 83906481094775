import React from "react";
import {
  child,
  database,
  onValue,
  push,
  ref,
  remove,
  set,
  get
} from "../firebase/init-database.js";
import { auth } from "../firebase/init-auth.js";
import TagsInput from "react-tagsinput";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormText,
} from "reactstrap";
import classNames from "classnames";
import Multiselect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { groupBy } from "utils/other.js";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { notify } from "utils/notify.jsx";
import parse from "html-react-parser";
import Moment from 'moment';
import "moment/locale/fr";

class Message extends React.Component {
  constructor() {
    super();
    this.state = {
      modalMessageAction: "add",
      showModal: false,
      showOption: {},
      tags: [],
      networkId: "",
      networkName: "",
      selectedGroups: [],
      includeRss: false,
      days: [
        {
          selected: true,
          day: "lun",
        },
        {
          selected: true,
          day: "mar",
        },
        {
          selected: true,
          day: "mer",
        },
        {
          selected: true,
          day: "jeu",
        },
        {
          selected: true,
          day: "ven",
        },
        {
          selected: true,
          day: "sam",
        },
        {
          selected: true,
          day: "dim",
        },
      ],
      comments: "",
      messageTitle: "",
      messageContent: "",
      startDate: 0,
      endDate: 0,
      screens: [],
      groups: [
        {
          id: "0",
          name: "Tous les groupes",
        },
      ],
      messages: [],
      removeOneMessageConfirmAlert: null,
      removeAllMessagesConfirmAlert: null,
      toggleCheckedAllMessages: false,
      messageTitleBeforUpdating: "",
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOnChecked = this.handleOnChecked.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeTags = this.handleChangeTags.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeDays = this.handleChangeDays.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.isStartBeforEndDate = this.isStartBeforEndDate.bind(this);

    this.notificationAlert = React.createRef();
  }

  handleOpenModal(action = "add") {
    this.setState({
      showModal: true,
      modalMessageAction: action,
    });
    if(action === "add") this.initAddMessage();
  }

  handleOnChecked() {
    this.setState((state) => ({
      includeRss: !state.includeRss,
    }));
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
      includeRss: false,
    });

  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleChangeTags(tags) {
    this.setState({ tags });
  }

  handleChangeMessage = (content, delta, source, editor) => {
    this.setState({
      messageContent: editor.getHTML(),
    });
  };

  handleChangeStartDate = (date) => {
    this.setState({
      startDate: date.valueOf() // timestamp millisecond format
    });
  };

  handleChangeEndDate = (date) => {
    this.setState({
      endDate: date.valueOf(),
    });
  };

  handleChangeDays = (event) => {
    const days = this.state.days;
    days.forEach((day, index) => {
      if (day.day === event.target.id) {
        days[index].selected = !day.selected;
      }
    });

    this.setState({
      days: days,
    });
  };

  validateAddMessageForm() {
    const isStartBeforEndDate = this.isStartBeforEndDate({startDate: this.state.startDate, endDate: this.state.endDate});
    return (
      this.state.messageTitle.length > 0 &&
      (this.state.includeRss
        ? this.state.tags.length > 0
        : (this.state.messageContent.length > 0) && (this.state.messageContent !== " ")) &&
      this.state.startDate > 0 &&
      (this.state.endDate > 0 ?  isStartBeforEndDate : true) &&
      this.state.days.some(day => day.selected)
    );
  }

  addDefaultGroup = (callback) => {
    const user=auth.currentUser;
    const usersRef = ref(database, 'users/' + user.uid);
    onValue(usersRef,
      (snapshot) => {
        const networkId = this.state.networkId;
        const defaultGroupRef = ref(database, 'networks/' + snapshot.val().network + '/groups/' + networkId);
        get(defaultGroupRef ).then((snapshot) => {
          if (!snapshot.exists()) {
            set(defaultGroupRef, {
              name: "_" + this.state.networkName,
              }
            );           
          }
          callback();
        }).catch((error) => {
          console.error(error);
        });              
      }, {
        onlyOnce: true
      });
    
  }

  addMessage = (notifyMessage = "") => {
    if (this.validateAddMessageForm()) {
      const user = auth.currentUser;
      const usersRef = ref(database, "users/" + user.uid);

      onValue(
        usersRef,
        (snapshot) => {
          const netRef = ref(database, "networks/" + snapshot.val()?.network);
          const groupsRef = child(netRef, "/groups/");
          const selectedGroups = this.state.selectedGroups;
          const startDate = Moment.isMoment(this.state.startDate) ? this.state.startDate.valueOf() : this.state.startDate;
          const endDate = Moment.isMoment(this.state.endDate) ? this.state.endDate.valueOf() : (this.state.endDate > 0 ? this.state.endDate : 0);

          if(selectedGroups && selectedGroups.length > 0) {
            selectedGroups.forEach((group) => {
              const messagesRef = child(groupsRef, group.id + "/messages/");
              push(messagesRef, {
                title: this.state.messageTitle,
                message: this.state.includeRss ? "" : this.state.messageContent,
                urls: this.state.includeRss ? this.state.tags : [],
                state: false,
                startDate: startDate,
                endDate: endDate,
                days: this.state.days,
              });
            });
          } else {
            this.addDefaultGroup(() => {              
              const messagesRef = child(groupsRef, this.state.networkId + "/messages/");            
              push(messagesRef, {
                title: this.state.messageTitle,
                message: this.state.includeRss ? "" : this.state.messageContent,
                urls: this.state.includeRss ? this.state.tags : [],
                state: false,
                startDate: startDate,
                endDate: endDate,
                days: this.state.days,
              })
            });
          }
         
          const message = notifyMessage ? notifyMessage : `Le message ${this.state.messageTitle} a été ajouté`;
          this.handleCloseModal();
          notify({
            ref: this.notificationAlert,
            type: "success",
            place: "br",
            message,
          });
        },
        {
          onlyOnce: true,
        }
      );
    }
  };

  updateMessage = ({ messageLabel = "" }) => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    if (this.validateAddMessageForm()) {
      onValue(
        usersRef,
        (snapshot) => {
          this.removeMessage(messageLabel);
          this.addMessage("Le message a été mis à jour");
        },
        {
          onlyOnce: true,
        }
      );
    }
  };

  hydrateUpdateMessageModalForm = ({ messageTitle }) => {
    const messagesGroupedByTitle = this.state.messages;
    const messageByTitle = messagesGroupedByTitle.filter(
      (messageByTitle) => messageByTitle.title === messageTitle
    )[0];
    const groups = [];
    const messages = messageByTitle.data;
    const message = messages[0];

    messages.forEach((message) => {
      const group = this.state.groups.filter(
        (group) => group.id === message.groupId
      )[0];
      if (group) groups.push(group);
    });

    this.setState(
      {
        selectedGroups: [...groups],
        messageTitle: message.title,
        messageTitleBeforUpdating: message.title,
        messageContent: message.content,
        startDate: Moment(message.startDate),
        endDate: message.endDate > 0 ?  Moment(message.endDate) : 0,
        includeRss: message.urls ? true : false,
        tags: message.urls ? message.urls : [],
        days: message.days,
      },
      () => {
        this.handleOpenModal("update");
      }
    );
  };

  initAddMessage = (event) => {
    this.setState({
      selectedGroups: [],
      messageTitle: "",
      messageContent: "",
      startDate: Moment.now(),
      endDate: 0,
      includeRss: false,
      tags: [],
      days: [
        {
          selected: true,
          day: "lun",
        },
        {
          selected: true,
          day: "mar",
        },
        {
          selected: true,
          day: "mer",
        },
        {
          selected: true,
          day: "jeu",
        },
        {
          selected: true,
          day: "ven",
        },
        {
          selected: true,
          day: "sam",
        },
        {
          selected: true,
          day: "dim",
        },
      ],
    });
  };

  toggleCheckedForAllMessages = (event) => {
    const toggleCheckedAllMessages = this.state.toggleCheckedAllMessages;
    if (this.state.messages.length > 0) {
      const messages = this.state.messages;
      messages.forEach((message) => {
        message.checked = event.target.checked;
      });

      this.setState({
        toggleCheckedAllMessages: !toggleCheckedAllMessages,
        messages: [...messages],
      });
    } else {
      event.preventDefault();
    }
  };

  toggleCheckedMessage = ({ messageTitle = "" }) => {
    const messages = this.state.messages;
    messages.forEach((message) => {
      if (message.title === messageTitle) {
        message.checked = !message.checked;
      }
    });

    this.setState({
      messages: [...messages],
      toggleCheckedAllMessages:
        this.checkedMessages().length === messages.length,
    });
  };

  removeMessage = (messageLabel) => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    onValue(
      usersRef,
      (snapshot) => {
        const netRef = ref(database, "networks/" + snapshot.val()?.network);
        const groupsRef = child(netRef, "/groups/");
        const messagesGroupByTitle = this.state.messages;

        const messageWithMessageLabelTitle = messagesGroupByTitle.filter(
          (messageByTitle) => messageByTitle.title === messageLabel
        )[0];
        const messages = messageWithMessageLabelTitle.data;

        messages.forEach((message) => {
          const messageRef = child(
            groupsRef,
            message.groupId + "/messages/" + message.id
          );
          remove(messageRef);
        });
      },
      {
        onlyOnce: true,
      }
    );
  };

  removeAllMessages = () => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    onValue(usersRef, (snapshot) => {
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      const groupsRef = child(netRef, "/groups/");

      onValue(
        groupsRef,
        (snap) => {
          const result = snap.val();
          for (let key in result) {
            const messageRef = child(groupsRef, key + "/messages/");
            remove(messageRef);
          }
        },
        {
          onlyOnce: true,
        }
      );
    });
  };

  checkedMessages = () => {
    const messages = this.state.messages;
    return messages.filter((message) => message.checked);
  };

  removeSelectedMessages = () => {
    if (this.state.toggleCheckedAllMessages) {
      this.removeAllMessages();
      this.setState({
        toggleCheckedAllMessages: false,
      });

      notify({
        ref: this.notificationAlert,
        type: "success",
        place: "br",
        message: `${this.checkedMessages().length} messages supprimés`,
      });
      return;
    }

    if (this.checkedMessages().length > 0) {
      const messagesGroupByTitle = this.checkedMessages();
      messagesGroupByTitle.forEach((messageByTitle) => {
        const messages = messageByTitle.data;
        messages.forEach((message) => {
          this.removeMessage(message.title);
        });
      });
      notify({
        ref: this.notificationAlert,
        type: "success",
        place: "br",
        message: `${this.checkedMessages().length} messages supprimés`,
      });
    }
  };

  removeOneMessageConfirmAlert = ({ messageLabel }) => {
    this.setState({
      removeOneMessageConfirmAlert: (
        <SweetAlert
          warning
          showCancel
          style={{ display: "block", marginTop: "-100px" }}
          title={`Voulez-vous supprimez ${messageLabel} ?`}
          confirmBtnText="Oui"
          cancelBtnText="Annuler"
          onConfirm={(e) => {
            this.removeMessage(messageLabel);
            this.hideRemoveOneMessageConfirmAlert();
            notify({
              ref: this.notificationAlert,
              type: "success",
              place: "br",
              message: `Le message ${messageLabel} a été supprimé`,
            });
          }}
          onCancel={() => this.hideRemoveOneMessageConfirmAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize="md"
        />
      ),
    });
  };

  removeAllMessagesConfirmAlert = () => {
    if (this.checkedMessages().length > 0) {
      this.setState({
        removeAllMessagesConfirmAlert: (
          <SweetAlert
            warning
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title={`Voulez-vous supprimez ces ${
              this.checkedMessages().length
            } messages ?`}
            confirmBtnText="Oui"
            cancelBtnText="Annuler"
            onConfirm={(e) => {
              this.removeSelectedMessages();
              this.hideRemoveAllMessagesConfirmAlert();
            }}
            onCancel={() => this.hideRemoveAllMessagesConfirmAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            btnSize="md"
          />
        ),
      });
    }
  };

  hideRemoveOneMessageConfirmAlert = () => {
    this.setState({
      removeOneMessageConfirmAlert: null,
    });
  };

  hideRemoveAllMessagesConfirmAlert = () => {
    this.setState({
      removeAllMessagesConfirmAlert: null,
    });
  };

  isValidDate = (currDate) => {
    const yesterday = Moment().subtract(1, "day");
    return currDate.isAfter(yesterday, "day")
  }

  isStartBeforEndDate = ({startDate, endDate}) => {
    const start = Moment(startDate);
    const end = Moment(endDate);
    return start.isBefore(end);
  }

  componentDidMount() {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    onValue(usersRef, (snapshot) => {
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      const groupsRef = child(netRef, "/groups/");

      onValue(netRef, (snap) => {
        const name = snap.val()?.name;
        const id = snap.key
        this.setState({networkName: name, networkId: id}, () => {
          onValue(groupsRef, (snap) => {
            const result = snap.val();
            const list = [];
            let messages = [];
            const messagesByGroups = [
              {
                groupId: 0,
                messages: [],
              },
            ];
    
            for (let key in result) {
              const messagesRef = child(groupsRef, key + "/messages/");
    
              const messagesByGroup = [];
    
              onValue(messagesRef, (snap) => {
                const result = snap.val();
                for (let key in result) {
                  messagesByGroup.push({
                    id: key,
                    title: result[key].title,
                    content: result[key].message,
                    urls: result[key].urls,
                    startDate: result[key].startDate,
                    endDate: result[key].endDate,
                    days: result[key].days,
                  });
                }
              });
    
              messagesByGroups.push({
                groupId: key,
                messages: messagesByGroup,
              });
    
              if(key !== this.state.networkId) {
                list.push({
                  id: key,
                  name: result[key]?.name,
                });
              }          
            }
    
            messagesByGroups.forEach((messageByGroup) => {
              messageByGroup.messages.forEach((message) => {
                const newMessage = {
                  id: message.id,
                  title: message.title,
                  content: message.content,
                  urls: message.urls,
                  endDate: message.endDate,
                  startDate: message.startDate,
                  days: message.days,
                  groupId: messageByGroup.groupId,
                };
    
                messages.push(newMessage);
              });
            });
    
            messages = groupBy(messages, "title");
            this.setState({
              groups: list,
              messages: messages,
            });
          });
        })
      })      
    });
  }

  renderMessaage = () => {
    const groups_list = this.state.groups;
    
    const messagesGroupedByTitle = this.state.messages;
    const messages_widget = [];

    messagesGroupedByTitle.forEach((messageByTitle) => {
      const groups_widget = [];
      const messages = messageByTitle.data;
      const message = messages[0];
      messages.forEach((_message) => {
        const group = groups_list.filter((group) => group.id === _message.groupId)[0];

        if(group) {
          groups_widget.push(
            <span key={group.id} className="badge badge-default">
              {group.name?.toUpperCase()}
            </span>
          );
        } else {
          groups_widget.push(
            <span key={this.state.networkId} className="badge badge-default">
              {"_" + this.state.networkName?.toUpperCase()}
            </span>
          );
        }       
      });

      messages_widget.push(
        <tr key={message.id}>
          <td className="text-center">
            <FormGroup check>
              <Label check>
                <Input
                  checked={messageByTitle.checked}
                  onChange={() =>
                    this.toggleCheckedMessage({ messageTitle: message.title })
                  }
                  type="checkbox"
                />
                <span className="form-check-sign">
                  <span className="check" />
                </span>
              </Label>
            </FormGroup>
          </td>
          <td><b>{message.title}</b><br/>{parse(message.content)}</td>
          <td className="text-center">{groups_widget}</td>
          <td className="text-center">
            {
              message.endDate > 0 
              ? (
                  Moment(message.startDate).isSame(message.endDate, "day") 
                  ? Moment(message.startDate).format("DD MMM YYYY [de] kk:mm") + " - " + Moment(message.endDate).format("kk:mm")
                  : Moment(message.startDate).format("DD MMM YYYY à kk:mm") + " - " + Moment(message.endDate).format("DD MMM YYYY à kk:mm")
                )
              : Moment(message.startDate).format("DD MMM YYYY à kk:mm")
            }
          </td>
          <td className="text-center">
            <a>
              <Button
                className="btn-icon btn btn-success"
                title="Modifier"
                color="link"
                onClick={() =>
                  this.hydrateUpdateMessageModalForm({
                    messageTitle: message.title,
                  })
                }
                disabled={
                  this.state.toggleCheckedAllMessages ||
                  this.checkedMessages().length > 1
                }
              >
                <i className="tim-icons icon-pencil" />
              </Button>
              <Button
                className="btn-icon btn btn-danger"
                onClick={() =>
                  this.removeOneMessageConfirmAlert({
                    messageLabel: message.title,
                  })
                }
                color="link"
                title="Supprimer définitivement"
                disabled={
                  this.state.toggleCheckedAllMessages ||
                  this.checkedMessages().length > 1
                }
              >
                <i className="tim-icons icon-trash-simple" />
              </Button>
            </a>
            {this.state.removeOneMessageConfirmAlert}
          </td>
        </tr>
      );
    });

    return messages_widget.map(message => message);
  }

  render() {
   
    const options_days = [];
    const days = this.state.days;

    for (const day of days) {
      options_days.push(
        <Col key={day.day}>
          <FormGroup check>
            <Label check>
              <Input
                id={day.day}
                value={day.selected}
                checked={day.selected}
                type="checkbox"
                onChange={this.handleChangeDays}
              />
              <span className="form-check-sign">
                <span className="check" />
              </span>
              {day.day}
            </Label>
          </FormGroup>
        </Col>
      );
    }

    return (
      <>
        <div className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={this.notificationAlert} />
          </div>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="6">
                      <CardTitle tag="h4">Messages</CardTitle>
                      {/* <p className="category">Here is a subtitle for this table</p> */}
                    </Col>
                    <Col sm="6">
                      <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                      >
                        <Button
                          tag="label"
                          className={classNames("btn-simple", {
                            active: false,
                          })}
                          color="info"
                          id="0"
                          size="sm"
                          onClick={() => this.removeAllMessagesConfirmAlert()}
                          disabled={!(this.checkedMessages().length > 1)}
                        >
                          <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Supprimer
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-trash-simple" />
                          </span>
                        </Button>
                        {this.state.removeAllMessagesConfirmAlert}
                        <Button
                          color="info"
                          id="1"
                          size="sm"
                          tag="label"
                          className={classNames("btn-simple", {
                            active: true,
                          })}
                          onClick={() => this.handleOpenModal("add")}
                        >
                          <input
                            className="d-none"
                            name="options"
                            type="radio"
                          />
                          <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                            Nouveau message
                          </span>
                          <span className="d-block d-sm-none">
                            <i className="tim-icons icon-bullet-list-67" />
                          </span>
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="tablesorter" responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">
                          <FormGroup check>
                            <Label check>
                              <Input
                                onClick={(e) =>
                                  this.toggleCheckedForAllMessages(e)
                                }
                                checked={this.state.toggleCheckedAllMessages}
                                type="checkbox"
                              />
                              <span className="form-check-sign">
                                <span className="check" />
                              </span>
                            </Label>
                          </FormGroup>
                        </th>
                        <th>Contenu</th>
                        <th className="text-center">Lire sur</th>
                        <th className="text-center">Période</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderMessaage()}</tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Col md="3"></Col>
            <Col md="5">
              <Modal
                isOpen={this.state.showModal}
                toggle={this.handleCloseModal}
                size="lg"
                className="modal modal-black xl modal-content "
                style={{ overflowY: "hidden", overflow: "visible" }}
              >
                <ModalHeader
                  className="modal-header"
                  toggle={this.handleCloseModal}
                >
                  <div className="text-center">
                    <div>
                      <b>
                        {this.state.modalMessageAction === "add"
                          ? "Diffusion d'un message"
                          : `Modification du message ${this.state.messageTitleBeforUpdating}`}
                      </b>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody className="modal-body">
                  <Col md="12">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Titre</label>
                          <Input
                            id="messageTitle"
                            placeholder="Titre du message"
                            onChange={this.handleChange}
                            type="text"
                            value={this.state.messageTitle}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup check>
                          <Label check>
                            <Input
                              defaultValue=""
                              type="checkbox"
                              checked={this.state.includeRss}
                              onChange={this.handleOnChecked}
                            />
                            <span className="form-check-sign">
                              <span className="check" />
                            </span>
                            <u>Flux RSS</u>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    {!this.state.includeRss ? (
                      <div>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label>Texte du message</label>
                              <ReactQuill
                                theme="snow"
                                modules={{
                                  toolbar: [
                                    [{ font: [] }],
                                    [
                                      {
                                        size: ["small", false, "large", "huge"],
                                      },
                                    ],
                                    ["bold", "italic", "underline"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    [{ align: [] }],
                                    [{ color: [] }, { background: [] }],
                                    ["clean"],
                                  ],
                                }}
                                formats={[
                                  "font",
                                  "size",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "list",
                                  "bullet",
                                  "align",
                                  "color",
                                  "background",
                                ]}
                                onChange={this.handleChangeMessage}
                                value={this.state.messageContent || ""}
                                rows="800"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <TagsInput
                                id="tags"
                                placeholder="test"
                                tagProps={{
                                  className: "react-tagsinput-tag info",
                                }}
                                inputProps={{
                                  className: "react-tagsinput-input",
                                  placeholder: "Saisir ici...",
                                }}
                                value={this.state.tags}
                                onChange={this.handleChangeTags}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>A diffuser sur</label>
                          <Multiselect
                            className="react-select info"
                            classNamePrefix="react-select"
                            placeholder="Ajouter un groupe..."
                            displayValue="name"
                            selectedValues={this.state.selectedGroups}
                            onSelect={(group) => {
                              this.setState({ selectedGroups: group });
                            }}
                            onRemove={(selectedList, selectedIGroup) => {
                              let newList = this.state.selectedGroups.filter(
                                (group) => group.id !== selectedIGroup.id
                              );
                              this.setState({ selectedGroups: [...newList] });
                            }}
                            options={this.state.groups}
                            style={{
                              tags: {
                                background: "primary",
                              },
                              multiselectContainer: {
                                color: "red",
                              },
                              optionContainer: {
                                background: "#1e1e2f",
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Début</label>
                          <Datetime
                            initialValue={this.state.startDate}
                            onChange={this.handleChangeStartDate}
                            inputProps={{ placeholder: "Début" }}
                            isValidDate={this.isValidDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Fin</label>
                          <Datetime
                            initialValue={this.state.endDate}
                            onChange={this.handleChangeEndDate}
                            inputProps={{ placeholder: "Fin" }}
                            isValidDate={this.isValidDate}
                          />
                          {
                            (this.state.endDate > 0 && !(this.isStartBeforEndDate({startDate: this.state.startDate, endDate: this.state.endDate}))) 
                            ? (
                              
                                Moment(this.state.startDate).isSame(Moment(this.state.endDate), "day") ? (
                                  <FormText color="danger">
                                    L'heure de fin doit être au dessus de celle de début
                                  </FormText>
                                )
                                :(
                                  <FormText color="danger">
                                    La date de fin doit être au dessus de celle de début
                                  </FormText>
                                  )
                              
                              )
                            : null
                          }                       
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">Jours de diffussion</Col>
                      {options_days}
                    </Row>
                  </Col>
                </ModalBody>

                <ModalFooter>
                  <button
                    className="btn btn-danger"
                    onClick={this.handleCloseModal}
                  >
                    Fermer
                  </button>
                  {this.state.modalMessageAction === "add" ? (
                    <button
                      className="btn btn-info"
                      onClick={() => this.addMessage(null)}
                      disabled={!this.validateAddMessageForm()}
                    >
                      Diffuser
                    </button>
                  ) : (
                    <button
                      className="btn btn-info"
                      onClick={() =>
                        this.updateMessage({
                          messageLabel: this.state.messageTitleBeforUpdating,
                        })
                      }
                      disabled={!this.validateAddMessageForm()}
                    >
                      Modifier
                    </button>
                  )}
                </ModalFooter>
              </Modal>
            </Col>
            <Col md="3"></Col>
          </div>
        </div>
      </>
    );
  }
}
export default Message;
