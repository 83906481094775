import {Button, Col, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {notify} from "../../utils/notify";
import {removeVideo} from "../../firebase/storage";

function FolderVideosList({
    folder,
    notificationAlertRef
}) {
    const [removeOneVideoConfirmAlertState, setRemoveOneVideoConfirmAlertState] = useState(null);

    const removeOneVideoConfirmAlert = ({folder, videoLabel, videoId}) => {
        setRemoveOneVideoConfirmAlertState(
                <SweetAlert
                    warning
                    showCancel
                    style={{display: "block",marginTop: "-100px"}}
                    title={`Voulez-vous supprimez ${videoLabel} ?`}
                    confirmBtnText="Oui"
                    cancelBtnText="Annuler"
                    onConfirm={() => {
                        removeVideo({
                            folder,
                            videoId,
                            videoLabel,
                        })
                        hideRemoveOneVideoConfirmAlert()
                        notify({ref: notificationAlertRef, type: "success", place: "br", message: `La vidéo ${videoLabel} a été supprimée`});
                    }}
                    onCancel={() => hideRemoveOneVideoConfirmAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    btnSize="md"
                />
            );
    }

    const hideRemoveOneVideoConfirmAlert = () => {
        setRemoveOneVideoConfirmAlertState(null)
    }


    return folder.videos?.length > 0 && folder.videos.map((video) => (
        <Row key={video.id} className="align-items-center">
            <Col md={{size: 4, offset: 1}}>
                <Row className="align-items-center">
                    <Col md={2}>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                            <video
                                width="30" height="30"
                                key={video?.id}

                            >
                                <source src={video?.url} type="video/mp4" />
                            </video>
                        </a>
                    </Col>
                    <Col>
                        {video?.label}
                    </Col>
                </Row>
            </Col>
            <Col md={2} className="px-xl-5">
                {video?.size}
            </Col>
            <Col className="text-center">
                {folder?.date}
            </Col>
            <Col md={2} className="pl-5 text-center">
                <a className="pl-2">
                    <Button className="btn-icon btn btn-danger"
                            onClick={() => removeOneVideoConfirmAlert({folder: folder, videoLabel: video?.label, videoId: video?.id})}
                            color="link" title="Supprimer définitivement"
                    >
                        <i className="tim-icons icon-simple-remove" />
                    </Button>
                    {removeOneVideoConfirmAlertState}
                </a>
            </Col>
        </Row>
    ))
}

export default FolderVideosList;