import {Col, FormGroup, FormText, Row} from "reactstrap";
import Datetime from "react-datetime";
import Moment from "moment/moment";
import React, {useEffect} from "react";
import {isStartBeforeEndDate} from "../utils/date";

function StartAndEndDate({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
}) {


    function isValidDate(currDate) {
        const yesterday = Moment().subtract(1, "day");
        return currDate.isAfter(yesterday, "day")
    }

    return (
        <Row>
            <Col>
                <FormGroup>
                    <label>Début</label>
                    <Datetime
                        value={startDate}
                        onChange={setStartDate}
                        inputProps={{ placeholder: "Début" }}
                        isValidDate={isValidDate}
                    />
                </FormGroup>
            </Col>
            <Col>
                <FormGroup>
                    <label>Fin</label>
                    <Datetime
                        value={endDate}
                        onChange={setEndDate}
                        inputProps={{ placeholder: "Fin" }}
                        isValidDate={isValidDate}
                    />
                    {
                        (endDate > 0 && !(isStartBeforeEndDate({startDate: startDate, endDate: endDate})))
                            ? (

                                Moment(startDate).isSame(Moment(endDate), "day") ? (
                                        <FormText color="danger">
                                            L'heure de fin doit être au dessus de celle de début
                                        </FormText>
                                    )
                                    :(
                                        <FormText color="danger">
                                            La date de fin doit être au dessus de celle de début
                                        </FormText>
                                    )

                            )
                            : null
                    }
                </FormGroup>
            </Col>
        </Row>
    );
}

export default StartAndEndDate;