import {Button, ButtonGroup, Col, Row} from "reactstrap";
import classNames from "classnames";
import React from "react";

function ScreenHeader({
    screenModalRef,
    groupModalRef,
}) {
    return (
        <>
            <Col sm="6">
                <h5 className="title">Ecrans</h5>
                <p className="category">
                    Tous vos ecrans
                </p>
            </Col>
            <Col sm="6">
                <ButtonGroup
                    className="btn-group-toggle float-right"
                    data-toggle="buttons">
                    <Button
                        tag="label"
                        className={classNames("btn-simple", {
                            active: false
                        })}
                        color="info"
                        id="0"
                        size="sm"
                        onClick={() => groupModalRef?.current.handleOpenModal()}
                    >
                        <input
                            defaultChecked
                            className="d-none"
                            name="options"
                            type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                              Groupes
                            </span>
                        <span className="d-block d-sm-none">
                              <i className="tim-icons icon-trash-simple" />
                            </span>
                    </Button>

                    <Button
                        color="info"
                        id="1"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                            active: true
                        })}
                        onClick={() => screenModalRef?.current.handleOpenModal({action: "add", screenId: 0})}
                    >
                        <input
                            className="d-none"
                            name="options"
                            type="radio"
                        />
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                  Nouvel Ecran
                              </span>
                        <span className="d-block d-sm-none">
                                  <i className="tim-icons icon-video-66" />
                              </span>
                    </Button>
                </ButtonGroup>
            </Col>
        </>
    )
}

export default ScreenHeader;