import {Col, Form, FormGroup, Input, Row} from "reactstrap";
import React from "react";

function EditUserForm({
                          society, setSociety,
                          country, setCountry,
                          city, setCity,
                          postal, setPostal,
                          firstname, setFirstname,
                          lastname, setLastname,
                          password, setPassword,
                            newPassword, setNewPassword,
                          email, setEmail,
                          profil, setProfil,
                          action
                      }) {
    return <Form>
        <Row>
            <Col md="12">
                <FormGroup>
                    <label>Societé</label>
                    <Input
                        id="society"
                        onChange={e => setSociety(e.target.value)}
                        value={society}
                        placeholder="Nom de la société"
                        type="text"
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col className="pr-md-1" md="6">
                <FormGroup>
                    <label>Nom(s)</label>
                    <Input
                        id="lastname"
                        onChange={e => setLastname(e.target.value)}
                        value={lastname}
                        placeholder="Saisir Nom(s)"
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col className="pl-md-1" md="6">
                <FormGroup>
                    <label>Prénom(s)</label>
                    <Input
                        id="firstname"
                        onChange={e => setFirstname(e.target.value)}
                        value={firstname}
                        placeholder="Prénom(s)"
                        type="text"
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col className="pr-md-1" md={action === "add" ? 6 : 12}>
                <FormGroup>
                    <label htmlFor="exampleInputEmail1">
                        Adresse mail
                    </label>
                    <Input
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder="exemple@email.com"
                        type="email"/>
                </FormGroup>
            </Col>
            {
                action !== "update" && (
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                                Mot de passe
                            </label>
                            <Input
                                id="password"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                placeholder=""
                                type="password"/>
                        </FormGroup>
                    </Col>
                )
            }
            {
                action === "profile.edit" && (
                    <Col className="pl-md-1" md="6">
                        <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                                Nouveau Mot de passe
                            </label>
                            <Input
                                id="password"
                                onChange={e => setNewPassword(e.target.value)}
                                value={newPassword}
                                placeholder=""
                                type="password"/>
                        </FormGroup>
                    </Col>
                )
            }
        </Row>
        <Row>
            <Col className="pl-md-3" md="4">
                <FormGroup>
                    <label>Pays</label>
                    <Input
                        id="country"
                        onChange={e => setCountry(e.target.value)}
                        value={country}
                        placeholder="Pays"
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col className="px-md-2" md="4">
                <FormGroup>
                    <label>Ville</label>
                    <Input
                        id="city"
                        onChange={e => setCity(e.target.value)}
                        value={city}
                        placeholder="City"
                        type="text"
                    />
                </FormGroup>
            </Col>
            <Col md="4">
                <FormGroup>
                    <label>Code Postal</label>
                    <Input
                        id="postal"
                        onChange={e => setPostal(e.target.value)}
                        value={postal}
                        placeholder="ZIP Code"
                        type="number"/>
                </FormGroup>
            </Col>
        </Row>
        {
            action !== "profile.edit" && (
                <Row>
                    <Col md="12">
                        <label>Profil de l'utilisateur</label>
                        <select
                            id="profil"
                            className="form-control"
                            onChange={e => setProfil(e.target.value)}
                            style={{backgroundColor: "#1e1e2f"}}
                            value={profil}>
                            <option>Sélectionner ici</option>
                            <option value="technician">Technicien</option>
                            <option value="manager">Manager</option>
                            <option value="admin">Admin</option>
                        </select>
                    </Col>
                </Row>
            )
        }

        <Row>
        </Row>
    </Form>;
}

export default EditUserForm;