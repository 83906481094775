import React, {createRef, useEffect, useState} from "react";
import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col
} from "reactstrap";
import {addUser, updateUser} from "../firebase/user";
import {Link, useHistory, useParams} from "react-router-dom";
import {database, onValue, ref} from "../firebase/init-database";
import EditUserForm from "../components/User/EditUserForm";

function EditUser() {

  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [society, setSociety] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postal, setPostal] = useState("");
  const [profil, setProfil] = useState("");
  const [action, setAction] = useState("add");

  const notificationAlert = createRef();

  const history = useHistory();

  let { id } = useParams();
  const validateForm = () => {
    let passwordValidation = action === "add" ? password && password.length > 8 : true;
    return (email && society && country && city && profil)
      ? (
          email.length > 0 &&
          society.length > 0 &&
          profil.length > 0 &&
          country.length > 0 &&
          city.length > 0 &&
          passwordValidation
      )
      : false;
  }

  const hydrateUpdateUserForm = (user = {}) =>{
    setLastname(user.lastname);
    setFirstname(user.firstname);
    setEmail(user.email);
    setPassword(user.password);
    setSociety(user.society);
    setCity(user.city);
    setCountry(user.country);
    setProfil(user.profil);
    setPostal(user.postal);
  }

  const submit = e => {
    e.preventDefault();
    if(action === 'update') {
      updateUser({
        uid: id,
        lastname, firstname, email,
        city, country, postal, society,
        profil, notificationAlert, history
      })
    } else {
      addUser({
        lastname, firstname, email, password,
        city, country, postal,
        profil, society, notificationAlert, history
      });
    }
  }

  useEffect(() => {
    if(id) {
      setAction("update");
      const usersRef = ref(database, "users/" + id);
      onValue(usersRef, (snapshot) => {
        hydrateUpdateUserForm(snapshot.val());
      }, {
        onlyOnce: true
      });
    }
  }, []);



  return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlert}/>
        </div>
        <div className="mb-2">
          <Link className="btn-link" to="/admin/users">
            <i className="fa fa-arrow-left"/> Users
          </Link>
        </div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Ajout d'un utilisateur</h5>
              </CardHeader>
              <CardBody>
                <EditUserForm
                    action={action}
                    setSociety={(society) => setSociety(society)} society={society}
                    setLastname={(last) => setLastname(last)} lastname={lastname}
                    setFirstname={(first) => setFirstname(first)} firstname={firstname}
                    setEmail={(mail) => setEmail(mail)} email={email}
                    setPassword={(pass) => setPassword(pass)} password={password}
                    setCountry={(country) => setCountry(country)} country={country}
                    setCity={(city) => setCity(city)} city={city}
                    setPostal={(postal) => setPostal(postal)} postal={postal}
                    setProfil={(profil) => setProfil(profil)} profil={profil}
                    newPassword={newPassword}
                    setNewPassword={(newPassword) => setNewPassword(newPassword)}
                />

              </CardBody>
              <CardFooter>
                <Row className="justify-content-center justify-content-md-end">
                  <Col md={4}>
                    <Button className="btn btn-primary text-center w-100" color="primary" onClick={(e) => submit(e)}
                            disabled={!validateForm()}>
                      {action === "update" ? "Modifier" : "Créer"}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>

          </Col>
        </Row>
      </div>
  );

}

export default EditUser;
