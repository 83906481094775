import React from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    CardTitle,
    DropdownToggle,
    Modal,
    ModalBody, 
    ModalFooter,
    FormGroup,
    Input,
    DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import { Route, Link } from 'react-router-dom';
import ReactChipInput from "react-chip-input";

class See_screen extends React.Component {
    constructor () {
        super();
        this.state = {
          showModal: false,
          showOption: {},
          showModal1: false,
          values: [],
          chips: []
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal1 = this.handleOpenModal1.bind(this);
        this.handleCloseModal1 = this.handleCloseModal1.bind(this);

        
        
      }
      
      handleOpenModal () {
        this.setState({ showModal: true });
      }
      
      handleCloseModal () {
        this.setState({ showModal: false });
      } 
      
      handleOpenModal1 () {
        
        this.setState({ showModal1: true });
      }
      
      handleCloseModal1 () {
        this.setState({ showModal1: false });
      } 

      handleOptionChange = (field, event) => {
        event.preventDefault();
        let showOption = this.state.showOption;
        showOption[field] = event.target.value;
   
        if (field === "visible"){
          const tableValue = showOption["visible"];
          this.setState({showOption});
        }
     }

     addChip = value => {
        const chips = this.state.chips.slice();
        chips.push(value);
        this.setState({ chips });
      };
      removeChip = index => {
        const chips = this.state.chips.slice();
        chips.splice(index, 1);
        this.setState({ chips });
      };
   
      handleChange = values => {
        this.setState({ values });
      };

      render() {
        const colourOptions = [
          { value: 'Ecran 1', label: 'Ecran 1', color: '#00B8D9', isFixed: true },
          { value: 'Ecran 2', label: 'Ecran 2', color: '#0052CC', disabled: true },
          { value: 'Ecran 3', label: 'Ecran 3', color: '#5243AA' },
          { value: 'Ecran 4', label: 'Ecran 4', color: '#FF5630', isFixed: true },
        ];
        const { values } = this.state;
        const modalStyle = {
            overlay: {
              position: "fixed",
              top: "10px",
              width: "700px",
              left: "350px",
              right: "5px",
              bottom: "5px"
            }
          };

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
                <h5 className="title">Toutes les playlist</h5>
                
                <Row>
                    <Col lg="3">
                        <Card className="card-chart" >
                            <CardHeader>
                                <CardTitle tag="span">
                                    <Link to="/admin/playlist" style={{color:'white'}}>
                                        Playlist 1
                                    </Link>
                                </CardTitle>
                                {/* <h3 className="card-category">Total Shipments</h3> */}
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        caret
                                        className="btn-icon"
                                        color="link"
                                        data-toggle="dropdown"
                                        type="button"
                                    >
                                        <i className="tim-icons icon-settings-gear-63" />
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={this.handleOpenModal1}
                                        >
                                            Ajouter une vidéo
                                        </DropdownItem>
                                        {/* <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Etat
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Ajouter rapport
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardHeader>
                            <CardBody style={{margin:'auto'}}>
                              <Link to="/admin/playlist">
                                <img src={require('../assets/img/logo-ie.png')} height='110px' alt=""/>                                
                              </Link>
                            </CardBody>
                            {/* <CardFooter>
                                <i className="tim-icons icon-wifi tim-icons-bg-square" title="wifi"/>
                                <> </>
                                <i className="tim-icons icon-bullet-list-67" title="playlist"/>
                                <> </> 
                                <i className="tim-icons icon-paper" title="message de l'écran"/>
                                <> </>  
                                <i className="tim-icons icon-button-power" title="allumer/éteindre"/>
                                <> </>  
                                <i style={{position: 'absolute', right:20}}  className="tim-icons icon-simple-add text-right" title="ajouter une vidéo"/>
                                <> </>                                                   
                            </CardFooter> */}
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card className="card-chart" >
                            <CardHeader>
                              <Link to="/admin/playlist" style={{color:'white'}}>
                                        Playlist 2
                              </Link>
                               
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        caret
                                        className="btn-icon"
                                        color="link"
                                        data-toggle="dropdown"
                                        type="button"
                                    >
                                        <i className="tim-icons icon-settings-gear-63" />
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                                    <DropdownItem
                                            href="#pablo"
                                            onClick={this.handleOpenModal1}
                                        >
                                            Ajouter une vidéo
                                        </DropdownItem>
                                        {/* <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Etat
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Ajouter rapport
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardHeader>
                            <CardBody style={{margin:'auto'}} >
                              <Link to="/admin/playlist">
                                <img src={require('../assets/img/setrag.png')} height='110px' alt=""/>                                
                              </Link>
                            </CardBody>
                            {/* <CardFooter>
                                <i className="tim-icons icon-wifi tim-icons-bg-square" title="wifi"/>
                                <> </>
                                <i className="tim-icons icon-bullet-list-67" title="playlist"/>
                                <> </> 
                                <i className="tim-icons icon-paper" title="message de l'écran"/>
                                <> </> 
                                <i className="tim-icons icon-button-power" title="allumer/éteindre"/>
                                <> </>                                                           
                            </CardFooter> */}
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card className="card-chart" >
                            <CardHeader>
                                <Link to="/admin/playlist" style={{color:'white'}}>
                                    Playlist 3
                                  </Link>
                               
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        caret
                                        className="btn-icon"
                                        color="link"
                                        data-toggle="dropdown"
                                        type="button"
                                    >
                                        <i className="tim-icons icon-settings-gear-63" />
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                                    <DropdownItem
                                            href="#pablo"
                                            onClick={this.handleOpenModal1}
                                        >
                                            Ajouter une vidéo
                                        </DropdownItem>
                                        {/* <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Etat
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Ajouter rapport
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardHeader>
                            <CardBody style={{margin:'auto'}}>
                              <Link to="/admin/playlist">
                                <img src={require('../assets/img/malin.jpg')} height='110px' alt=""/>
                              </Link>
                            </CardBody>
                            {/* <CardFooter>
                                <i className="tim-icons icon-wifi tim-icons-bg-square" title="wifi"/>
                                <> </>
                                <i className="tim-icons icon-bullet-list-67" title="playlist"/>
                                <> </> 
                                <i className="tim-icons icon-paper" title="message de l'écran"/>
                                <> </> 
                                <i className="tim-icons icon-button-power" title="allumer/éteindre"/>
                                <> </>                                                           
                            </CardFooter> */}
                        </Card>
                    </Col>
                    <Col lg="3">
                        <Card className="card-chart" >
                            <CardHeader>
                                  <Link to="/admin/playlist" style={{color:'white'}}>
                                    Playlist 4
                                  </Link>
                             
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        caret
                                        className="btn-icon"
                                        color="link"
                                        data-toggle="dropdown"
                                        type="button"
                                    >
                                        <i className="tim-icons icon-settings-gear-63" />
                                    </DropdownToggle>
                                    <DropdownMenu aria-labelledby="dropdownMenuLink" right>
                                    <DropdownItem
                                            href="#pablo"
                                            onClick={this.handleOpenModal1}
                                        >
                                            Ajouter une vidéo
                                        </DropdownItem>
                                        {/* <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Etat
                                        </DropdownItem>
                                        <DropdownItem
                                            href="#pablo"
                                            onClick={e => e.preventDefault()}
                                        >
                                            Ajouter rapport
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </CardHeader>
                            <CardBody style={{margin:'auto'}}>
                              <Link to="/admin/playlist">
                                <img src={require('../assets/img/amigos.jpg')} height='110px' alt=""/>
                              </Link>
                            </CardBody>
                            {/* <CardFooter>
                                <i className="tim-icons icon-wifi tim-icons-bg-square" title="wifi"/>
                                <> </>
                                <i className="tim-icons icon-bullet-list-67" title="playlist"/>
                                <> </> 
                                <i className="tim-icons icon-paper" title="message de l'écran"/>
                                <> </> 
                                <i className="tim-icons icon-button-power" title="allumer/éteindre"/>
                                <> </>                                                           
                            </CardFooter> */}
                        </Card>
                    </Col>
                </Row>
            </Col>
            {/* Modal du bouton ajouté une vidéo à la playlist */}
            <Modal isOpen={this.state.showModal1} style={modalStyle}>
              <div className="text-center">
                 <div><b>Diffuser une vidéo ou un post</b></div>
              </div>
              <ModalBody>
                <Row>
                    <Col md="12">
                  <Row>
                      <Col md="6">
                      <FormGroup>
                            <label>Nom Vidéo</label>
                              {/*<select className="form-control">
                                <option >Sélectionner la Vidéo</option>
                                <option value="">Vidéo 1</option>
                                <option value="">Vidéo 2</option>
                                <option value="">Vidéo 3</option>
                          </select>*/}
                          </FormGroup>
                      </Col>
                      <Col md="6">
                          <FormGroup>
                            <label>Diffusion</label>
                              {/*<select className="form-control" name="visible" value={this.state.showOption.visible} 
                              onChange={this.handleOptionChange.bind(this,"visible")}     >
                                <option >Sélectionner le mode</option>
                                <option selected value="poster">Poster maintenant</option>
                                <option value="programmer">Programmer</option>
                        </select>*/}
                          </FormGroup>
                        </Col>
                      {/* <Col md="4">
                        <FormGroup>
                          <label>Ecrans</label>
                            <select className="form-control">
                              <option >Sélectionner ici</option>
                              <option value="">Ecran 1</option>
                              <option value="">Ecran 2</option>
                              <option value="">Ecran 3</option>
                            </select>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                        <Col md="12">
                          <FormGroup>
                            <label>Ecrans</label>
                            {/*<Select
                              hideSelectedOptions={false}
                              isMulti
                              options={colourOptions}
                              
                              onChange={this.handleChange}
                              value={values}
                            />*/}
                          </FormGroup>
                        </Col>                        
                      </Row>

                      {this.state.showOption.visible === "programmer" &&
                      <div>
                      <Row>
                        <Col md="3">
                          <FormGroup>
                            <label>Date début</label>
                            <Input
                              placeholder="Marque"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label>Heure début</label>
                            <Input
                              placeholder="Marque"
                              type="time"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label>Date fin</label>
                            <Input
                              placeholder="Marque"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup>
                            <label>Heure fin</label>
                            <Input
                              placeholder="Marque"
                              type="time"
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                    <Col md="12">
                          <FormGroup>
                            <label>Jour</label>
                            <ReactChipInput
                                classes="class1 class2"
                                chips={this.state.chips}
                                onSubmit={value => this.addChip(value)}
                                onRemove={index => this.removeChip(index)}
                            />
                          </FormGroup>
                        </Col>
                    </Row>
                    </div>}
                    </Col>
                </Row>    
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-danger" onClick={this.handleCloseModal1} >Fermer</button>
                <button className="btn btn-info" >Diffuser</button>
              </ModalFooter>
            </Modal>
          </Row>

          {/* Bouton du modal ajouté */}
          <Row>
            <Col md="12">
              <a href="#" style={{alignSelf: 'flex-end',position: 'absolute',top:30, right:0}}>
                <button className="btn-icon btn-round btn tim-icons icon-simple-add text-info" onClick={this.handleOpenModal} />
              </a>
            </Col>

            {/* Modal du bouton ajouté une playlist */}
            <Modal isOpen={this.state.showModal} style={modalStyle}>
              <div className="text-center">
                 <div><b>Enregistrement d'une playlist</b></div>
              </div>
              <ModalBody>
                <Row md="12">
                  <Col md="6">
                        <FormGroup>
                          <label>Nom de la playlist</label>
                          <Input
                            placeholder="Nom de la playlist"
                            type="text"
                          />
                        </FormGroup>
                  </Col>
                  <Col md="6">
                          <label>Vignette de la playlist</label>
                            <input type="file"
                              onChange={this.handleInputChange}
                              ref={input => this.fileInput = input}
                              className="form-control" />   
                  </Col>
                  </Row>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-danger" onClick={this.handleCloseModal} >Annuler</button>
                <button className="btn btn-info" >Enregistrer</button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </>
    );
  }
}

export default See_screen;
