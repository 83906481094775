import React from "react";
import { Route, Switch } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import { auth, onAuthStateChanged  } from "../../firebase/init-auth.js";

import routes from "routes.js";

import { messaging } from "../../firebase/init-fcm.js";
import { Spinner } from "reactstrap";
import {database, onValue, ref} from "../../firebase/init-database";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      society:'',
      backgroundColor: "blue",
      sidebarOpened:
      document.documentElement.className.indexOf("nav-open") !== -1,
      userProfile: "",
      isLoading: true
    };
  }
  
  async componentDidMount() {
    onAuthStateChanged(auth, (user) => {
      let sessionTimeout = null;
      if (user) {  
        this.setState({isLoading: false})

        document.onvisibilitychange = () => {
          if (document.visibilityState === 'hidden') { // Inactive Window
            // User is logged in.
            // Fetch the decoded ID token and create a session timeout which signs the user out.
            user.getIdTokenResult().then((idTokenResult) => {
              // Make sure all the times are in milliseconds!
              const authTime = idTokenResult.claims.auth_time * 1000;
              const sessionDuration = 900_000; // 15 min
              const millisecondsUntilExpiration = sessionDuration - (Date.now() - authTime);
              sessionTimeout = setTimeout(() => auth.signOut(), sessionDuration);
            });
          } else {
            sessionTimeout && clearTimeout(sessionTimeout);
            sessionTimeout = null;
          }
        };

        const usersRef = ref(database, "users/" + user.uid);
        onValue(usersRef, (snapshot) => {
          this.setState({ userProfile: snapshot.val()?.profil });
        })
          
      } else {
        // User is logged out.
        // Clear the session timeout.
        sessionTimeout && clearTimeout(sessionTimeout);
        sessionTimeout = null;
        this.props.history.push("/login")
      }
    });

    if (navigator.platform.indexOf("Win") > -1 && ps) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    try {    
    messaging.requestPermission()
      .then(async function() {
  			const token = await messaging.getToken();
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });
    } catch (error) {
      console.log(error)
    }
      navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
   
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      if(document.documentElement.scrollTop) document.documentElement.scrollTop = 0;
      if(document.scrollingElement.scrollTop )document.scrollingElement.scrollTop = 0;
      if(this.refs.mainPanel) this.refs.mainPanel.scrollTop = 0;
    }    
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1 && ps) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" &&  prop.UIs.includes(this.state.userProfile)) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    if(this.state.isLoading) {
      return (
        <Spinner>
          <span className="sr-only">Loading...</span>
        </Spinner>
      )
    }
    return (
      <>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={routes}
            bgColor={this.state.backgroundColor}
            logo={{
              outterLink: "#",
              text: this.state.society,
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}
          >
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            <Switch>{this.getRoutes(routes)}</Switch>
            {// we don't want the Footer to be rendered on map page
                this.props.location.pathname.indexOf("maps") !== -1 ? null : (
              <Footer fluid />
            )}
          </div>
        </div>
        <FixedPlugin
          bgColor={this.state.backgroundColor}
          handleBgClick={this.handleBgClick}
        />
      </>
    );
  }
}

export default Admin;
