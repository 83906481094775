import Screens from "views/Screens.jsx";
import SeeScreen from "views/See_screen.jsx";
import Login from "views/Login.jsx";
import Map from "views/Map.jsx";
import Notifications from "views/Notifications.jsx";
import Group from "views/Group.jsx";
import Playlist from "./views/Playlist.jsx";
import UserProfile from "./views/UserProfile.jsx";
import AddTools from "./views/AddTools.jsx";
import Tools from "./views/Tools.jsx";
import Networks from "./views/Networks.jsx";
import ScreenDetail from "./views/screen_detail.jsx";
import EditUser from "./views/EditUser.jsx";
import Storage from "./views/Storage.jsx";
import Message from "./views/Message.jsx";
import Program from "./views/Program.jsx";
import Register from "views/Register.jsx";
import Users from './views/Users.jsx';
import EditPlaylist from "./views/EditPlaylist.jsx";

const routes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-components",
    component: Map,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    path: "/screens",
    name: "Ecrans",
    rtlName: "الرموز",
    icon: "tim-icons icon-tv-2",
    component: Screens,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    path: "/screen-detail",
    name: "Ecran",
    rtlName: "الرموز",
    icon: "tim-icons icon-tv-2",
    component: ScreenDetail,
    layout: "/admin",
    redirect:true,
    UIs:["admin","technician","manager"]
  },
  {
    path: "/see-screen",
    name: "Ecran playlist",
    rtlName: "الرموز",
    icon: "tim-icons icon-triangle-right-17",
    component: SeeScreen,
    redirect:true,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    path: "/add-tools",
    name: "Ajouter un materiel",
    rtlName: "الرموز",
    icon: "tim-icons icon-tv-2",
    component: AddTools,
    layout: "/admin",
    redirect:true,
    UIs:["technician"]
  },

  {
    path: "/tools",
    name: "Materiel",
    rtlName: "الرموز",
    icon: "tim-icons icon-settings",
    component: Tools,
    layout: "/admin",
    redirect :true,
    UIs:["technician"]
  },  
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: "tim-icons icon-bell-55",
    component: Notifications,
    layout: "/admin",
    redirect:true,
    UIs:["admin","technician","manager"]
  },
  {
    path: "/",
    name: "Log In",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: Login,
    redirect:true,
    layout: "/login"
  },
  {
    path: "/",
    name: "Register",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: Register,
    redirect: true,
    layout: "/register"
  },
  {
    path: "/profile",
    name: "Profil",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-badge",
    component: UserProfile,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    path: "/users",
    name: "Utilisateurs",
    rtlName: "الرموز",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/admin",
    UIs:["admin"]
  },
  {
    path: "/user/:id/edit",
    name: "Ajouter",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: EditUser,
    layout: "/admin",
    redirect: true,
    UIs:["admin"]
  },
  {
    path: "/user/add",
    name: "Ajouter",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: EditUser,
    layout: "/admin",
    redirect: true,
    UIs:["admin"]
  },
  {
    path: "/networks",
    name: "Réseaux",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-vector",
    component: Networks,
    redirect:true,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },

  {
    path: "/groups",
    name: "Groupes",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-puzzle-10",
    component: Group,
    layout: "/admin",
    redirect:true,
    UIs:["admin","technician","manager"]
  },

  {
    path: "/messages",
    name: "Messages",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-email-85",
    component: Message,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },

  {
    name:"Playlist",
    path: "/playlists",
    rtlName: "الرموز",
    component: Playlist,
    icon: "tim-icons icon-bullet-list-67",
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    name:"Add Playlist",
    path: "/playlist/add",
    rtlName: "الرموز",
    component: EditPlaylist,
    icon: "tim-icons icon-bullet-list-67",
    layout: "/admin",
    redirect: true,
    UIs:["admin","technician","manager"]
  },
  {
    name:"Update Playlist",
    path: "/playlist/:id/edit",
    rtlName: "الرموز",
    component: EditPlaylist,
    icon: "tim-icons icon-bullet-list-67",
    layout: "/admin",
    redirect: true,
    UIs:["admin","technician","manager"]
  },
  {
    path: "/storages",
    name: "Stockage",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-chart-pie-36",
    component: Storage,
    layout: "/admin",
    UIs:["admin","technician","manager"]
  },
  {
    path: "/programs",
    name: "Programme",
    rtlName: "الرموز",
    icon: "tim-icons icon-calendar-60",
    component: Program,
    layout: "/admin",
    UIs:["admin", "manager"]
  },
];
export default routes;
