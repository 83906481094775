import React from "react";
import { auth } from "../firebase/init-auth.js";
import { database, ref, onValue } from "../firebase/init-database.js";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
      defaultOptions={{
        scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#8ec3b9"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1a3646"
              }
            ]
          },
          {
            featureType: "administrative.country",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#64779e"
              }
            ]
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#4b6878"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#334e87"
              }
            ]
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#283d6a"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#6f9ba5"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#3C7680"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#304a7d"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#2c6675"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#9d2a80"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#9d2a80"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#b0d5ce"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#023e58"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#98a5be"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#1d2c4d"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#283d6a"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#3a4762"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#0e1626"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#4e6d70"
              }
            ]
          }
        ]
      }}
    >
      <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
    </GoogleMap>
  ))
);

class Map extends React.Component {

  constructor (){
    super();
    this.state ={
      screens:[],
      count_online:0,
      count_offline:0,
      count_inactive:0
    }
  }

  componentDidMount(){
    const user=auth.currentUser;
    const usersRef = ref(database, '/users/' + user.uid);
    onValue(usersRef, (snapshot) => {
      const screensRef = ref(database, 'networks/' + snapshot.val()?.network +'/screens/'); 
      onValue(screensRef, (snap) => {
        var result=snap.val();
        var list=[];
        var count_o=0;
        var count_i=0
        for (let key in result){
          if(result[key]?.name==null)  
          count_i++;
          else
            if(result[key]?.online)
              count_o++;

          list.push({
            name: result[key]?.name,
            address: result[key]?.address,
            online: result[key]?.online,
            lon: result[key]?.lon,
            lat: result[key]?.lat,
            video_reading: result[key]?.isVideoReading
          })
        }
        this.setState({
          screens: list,
          count_online: count_o,
          count_inactive: count_i,
          count_offline: list.length-count_o-count_i
        })
        }, {
        onlyOnce: true
      })
    }, {
      onlyOnce: true
    });

   }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" className="d-flex align-items-stretch">
                <Card className="card-chart">
                    <CardHeader>
                      En ligne
                      <CardTitle tag="h1">
                        <i className="tim-icons icon-tv-2 online"/><b>{" "+this.state.count_online}</b>
                        <hr/>
                        <Row>
                          <Col lg="6">
                          </Col>
                          <Col lg="6">
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                  </Card>
              </Col>
              <Col lg="3" className="d-flex align-items-stretch">
                <Card className="card-chart">
                    <CardHeader>
                      Hors synchronisation
                      <CardTitle tag="h1">
                        <i className="tim-icons icon-tv-2 outline" /><b>{" 0"}</b>
                        <hr/>
                        <Row>
                          <Col lg="6">
                          </Col>
                          <Col lg="6">
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                  </Card>
              </Col>
              <Col lg="3" className="d-flex align-items-stretch">
                <Card className="card-chart">
                    <CardHeader>
                      Hors ligne
                      <CardTitle tag="h1">
                        <i className="tim-icons icon-tv-2 offline" /><b>{" "+this.state.count_offline}</b>
                        <hr/>
                        <Row>
                          <Col lg="6">
                          </Col>
                          <Col lg="6">
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                  </Card>
              </Col>
              <Col lg="3" className="d-flex align-items-stretch">
                <Card className="card-chart">
                    <CardHeader>
                      Inactifs
                      <CardTitle tag="h1">
                        <i className="tim-icons icon-tv-2 card-category" /><b>{this.state.count_inactive}</b>
                        <hr/>
                        <Row>
                          <Col lg="6">
                          </Col>
                          <Col lg="6">
                          </Col>
                        </Row>
                      </CardTitle>
                    </CardHeader>
                  </Card>
              </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>Emplacement des écrans</CardHeader>
                <CardBody>
                  <div
                    id="map"
                    className="map"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <MapWrapper
                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `100%` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Map;
