import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import React from "react";

function OptionsDays({
     days = [],
     setDays
 })  {

    const handleChangeDays = (event) => {
        const nDays = days;
        nDays.forEach((day, index) => {
            if (day.day === event.target.id) {
                nDays[index].selected = !day.selected;
            }
        });
        setDays([...nDays]);
    };


    return (days && days.length > 0 && days.map(day => (
            <Col key={day.day}>
                <FormGroup check>
                    <Label check>
                        <Input
                            id={day.day}
                            value={day.selected}
                            checked={day.selected}
                            type="checkbox"
                            onChange={handleChangeDays}
                        />
                        <span className="form-check-sign">
                <span className="check" />
              </span>
                        {day.day}
                    </Label>
                </FormGroup>
            </Col>
    )));
}
function DaysCheckbox({
    days = [],
    setDays
                      }) {
    return (
        <Row>
            <Col>
                <FormGroup>
                    <label>Jours de diffussion</label>
                    <Row>
                        <OptionsDays days={days} setDays={(days) => setDays(days)} />
                    </Row>
                </FormGroup>
            </Col>
        </Row>
    );
}

export default DaysCheckbox;