import React from 'react'
import { Route, Switch, Redirect } from "react-router-dom";
import LoginLayout from "layouts/Login/Login.jsx";
import RegisterLayout from "layouts/Register/Register.jsx";
import AdminLayout from "layouts/Admin/Admin.jsx";
import Moment from 'moment';

Moment.locale('fr');
function App() {
    return (
        <Switch>
            <Route path="/login" render={props => <LoginLayout {...props} />} />
            <Route path="/register" render={props => <RegisterLayout {...props} />} />
            <Route path="/admin/dashboard" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/screens" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/add-tools" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/tools" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/profile" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/networks" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/screen_detail" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/see-screen" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/users" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/user/:id/edit" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/user/add" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/groups" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/storages" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/messages" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/playlists" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/playlist/:id/edit" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/playlist/add" render={props => <AdminLayout {...props} />} />
            <Route path="/admin/programs" render={props => <AdminLayout {...props} />} />

            <Redirect from="/" to="/login" />
        </Switch>

    )
}

export default App;
