import React from "react";
import { gapi } from 'gapi-script';
import { database, onValue, push, ref, set} from "../firebase/init-database.js";

// reactstrap components
import {
    Card,
    CardHeader,
    Row,
    Col,
    CardTitle,
    Modal,
    ModalBody, 
    ModalFooter,
    FormGroup,
    Form,
    Input,
} from "reactstrap";
import { NavLink } from "react-router-dom";


class Screens extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
          showModal: false,
          networkKey:"",
          namegroup: '',
          namereseau: '',
          reseau: [],
          groups: [],
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.addGroups   = this.addGroups.bind(this);

        this.handleDropdownChange = this.handleDropdownChange.bind(this);
      }
      
      handleDropdownChange = e => {
        this.setState({ namereseau: e.target.value });
      }

      handleOpenModal () {
        this.setState({ showModal: true });
      }
      
      handleCloseModal () {
        this.setState({ showModal: false });
      } 


      handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        });
      }

    
      componentDidMount() {

        alert(this.props.location.state);
        const reseauRef = ref(database, 'reseau/');
        const groupsRef = ref(database, 'reseau/' + this.props.networkKey + '/groups/');
       onValue(reseauRef, snapshot => { 
            let allNotes = [];
            snapshot.forEach(snap => {
              allNotes.push(snap.val());
            });
            this.setState({ reseau: allNotes });
          });

        onValue(groupsRef, snapshot => {
          let allGroup = [];
          snapshot.forEach(snap => {
            allGroup.push(snap.val());
          });
          this.setState({ groups: allGroup });
        });
      }


    addGroups = event =>{
      const reseauRef = ref(database,'reseau/' + this.props.networkKey + '/groups/');
      const newReseauRef = push(reseauRef);
        set(newReseauRef, {
            namegroup:this.state.namegroup,namereseau:this.state.namereseau,}).then(_ => {
          });
      }



      render() {
        const modalStyle = {
          overlay: {
            position: "fixed",
            top: "10px",
            width: "900px",
            left: "300px",
            right: "5px",
            bottom: "5px"
          }
        };
        
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
                
    <h5 className="title">Groupe d'Ecrans {this.props.networkName}</h5>
                <p className="category">
                    Tous vos groupes
                </p>
                <Row>
                {this.state.groups.map( data => {
                    return (
                <Col lg="3">
                    <NavLink to={{
                        pathname: '/admin/groups',
                        state: {
                        //groupKey: data.key
                        }
                    }}>  
                    <Card className="card-chart">
                        <CardHeader>
                        <h5 className="card-category">763 Groupes</h5>
                        <CardTitle tag="h3">
                            <i className="tim-icons icon-image-02 text-info" />{" "}
                            {data.namegroup}
                            <hr/>
                            <Row>
                            <Col lg="6">
                            </Col>
                            <Col lg="6">
                            </Col>
                            </Row>
                        </CardTitle>
                        </CardHeader>
                    </Card>
                </NavLink>
                </Col>
                )})}
                </Row>
                </Col>
          </Row>
          <Row>
            <Col md="12">
              <a href="#" style={{alignSelf: 'flex-end',position: 'absolute',top:30, right:0}}>
                <button className="btn-icon btn-round btn tim-icons icon-simple-add text-info" onClick={this.handleOpenModal} />
              </a>
            </Col>
            <Modal isOpen={this.state.showModal} style={modalStyle}>
              <div className="text-center">
                 <div><b>Enregistrement d'un groupe</b></div>
              </div>
              <ModalBody>
                <div className="text-left" style={{marginLeft:"15px"}}>
                    <Form  onSubmit={this.addGroups}>   
                        <Row>
                            <Col className="pr-md-1" md="6">
                                <FormGroup>
                                    <label>Nom du réseau</label>
                                    <select 
                                        id="namereseau"
                                        className="form-control"
                                        value={this.state.namereseau} 
                                        onChange={this.handleDropdownChange}>

                                        {this.state.reseau.map(note => {
                                         return (
                                        <option value={note.value}>{note.libelle}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col className="pl-md-1" md="6">
                                <FormGroup>
                                    <label>Nom du groupe</label>
                                    <Input
                                        id="namegroup"
                                        placeholder="Nom du groupe"
                                        type="text"
                                        onChange={this.handleChange}
                                        value ={this.state.namegroup}
                                    />
                                </FormGroup>
                            </Col>            
                        </Row>
                    </Form>
                </div>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-danger" onClick={this.handleCloseModal} >Annuler</button>
                <button className="btn btn-info" onClick={this.addGroups} >Enregistrer</button>
              </ModalFooter>
            </Modal>
          </Row>
        </div>
      </>
    );
  }
}

class ScreenAdd extends React.Component{

    render(){
        return(<Col className="font-icon-list col-xs-6 col-xs-6"
                                         lg="3"
                                         md="3"
                                         sm="4">
                                           <div className="font-icon-detail">
                                                <i className="tim-icons icon-simple-add" />
                                                <p>icon-simple-add</p>
                                           </div>

                                       </Col>);
    }
}

class Screen extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            videoIdList: [],
            connected:require("assets/img/no-signal.png"),
            playing:null,
            error:null
        };
    }

    componentDidMount(){
        const maxResults=10;
        const googleApiKey='AIzaSyCfVai-IGtllkw2yQyZtbbEYSbpazMtLd4';
        const youtubeChannelId=this.props.youtubeChannelId;

        if (!googleApiKey){
          console.error('You must provide a googleApiKey prop');
          return;
        }

        if (!youtubeChannelId){
          console.error('You must provide a youtubeChannelId prop');
          return;
        }

        this.performAPIQuery(maxResults, googleApiKey, youtubeChannelId);
      }

    performAPIQuery(maxResults = 10, googleApiKey, youtubeChannelId){

        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";

        script.onload = () => {
          gapi.load('client', () => {
            gapi.client.setApiKey(googleApiKey);
            gapi.client.load('youtube', 'v3', () => {
              gapi.client.youtube.search.list({
                      part: 'snippet',
                      channelId: youtubeChannelId,
                      maxResults: maxResults,
                      type: 'video',
                      eventType: 'live'
                  }).then( (response) => {
                    let json = JSON.parse(response.body);

                    if(json.pageInfo.totalResults === 0){
                      this.setState({
                        videoIdList: []
                      });
                    } else {
                      let videoIdList = [];

                      for (let item of json.items){
                        videoIdList.push(item.id.videoId);
                      }
                      this.setState({
                        videoIdList: videoIdList
                      });
                    }
                  }, (reason) => {
                    console.log('Error: ' + reason.result.error.message);
                  });

            });
          });
        };

        document.body.appendChild(script);
     };

    online(){
        ref(database,this.props.firebaseId+'/online').on('value',snapshot=> {
            // If we're not currently connected, don't do anything.
            if (snapshot.val() === false) {
                if(this.state.connected!==require("assets/img/no-signal.png"))
                    this.setState({
                        connected:require("assets/img/no-signal.png"),
                        playing:null,
                        error:null
                    });
                return;
            }
            if(snapshot.val()===true){
                if(this.state.connected!==require("assets/img/wifi.png"))
                    this.setState({
                        connected:require("assets/img/wifi.png")
                    });
                    ref(database,this.props.firebaseId+'/isVideoReading').on('value',snapshot1=> {
                                   // If we're not currently connected, don't do anything.
                                   if (snapshot1.val() === true) {
                                       if(this.state.playing!==require("assets/img/pause.png"))
                                           this.setState({
                                               playing:require("assets/img/pause.png")
                                           });
                                       return null;
                                   }else{
                                       if(this.state.playing!==require("assets/img/play-button.png"))
                                           this.setState({
                                               playing:require("assets/img/play-button.png")
                                           });
                                       return null;
                                   }
                               });
                return;
            }
            if(snapshot.val()==null){
                // alert(1);
            }
        });
    };

    render(){
        this.online();
        return(<Col className="font-icon-list col-xs-6 col-xs-6"
                          lg="3"
                          md="3"
                          sm="4">
                            <div className="font-icon-detail">

                                <iframe className='video'
                                    src={"https://www.youtube.com/embed/"+this.state.videoIdList[0]+"?autoplay=1"  }
                                    frameBorder="0"
                                    allow="autoplay"
                                    allowFullScreen>
                                </iframe>

                              <Row float="left">
                                <Col>
                                    <img  src={this.state.connected} className="state-icons"/>

                                    <img  src={this.state.playing} className="state-icons"/>
                                <Col />
                                </Col>

                              </Row>

                             <p>{this.props.label}</p>
                            </div>
                        </Col>);
  }
}

export default Screens;
