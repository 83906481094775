import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function Footer() {
    return (
      <footer className="footer">
        <Container fluid>
          <div className="copyright">
            {new Date().getFullYear()} développé par{" "}
            <a
              href="https://www.initialde.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
             initial.de
            </a>{" "}
            , solution clé en un.
          </div>
        </Container>
      </footer>
    );
}

export default Footer;
