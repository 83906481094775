import React, {useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { auth, onAuthStateChanged, } from "../../firebase/init-auth.js";

import routes from "routes.js";

function Login () {

  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/login") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
        if (user) {     
            history.push("/admin/dashboard")
        } else {
          setIsLoading(false);
        }
    });
  }, []);



  if(isLoading) {
    return (
      <Spinner>
        <span className="sr-only">Loading...</span>
    </Spinner>
    )
  }

  return (
    <div className="wrapper">
      <div
        className="loginPanel"
      >
        <Row className="center">
            <Col sm="12"><Switch>{getRoutes(routes)}</Switch></Col>
        </Row>
      </div>
  </div>
  );

}

export default Login;