import {Button, FormGroup, Input, Label} from "reactstrap";
import Moment from "moment/moment";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {notify} from "../../utils/notify";
import {removePlaylist} from "../../firebase/playlist";

function PlaylistTable({
    playlists = [],
    networkId,
    notificationAlert,
    toggleCheckedPlaylist,
    checkedPlaylists,
    toggleCheckedAllPlaylists

                       }) {

    const history = useHistory();

    const [removeOnePlaylistConfirmAlertState, setRemoveOnePlaylistConfirmAlertState] = useState(null);
    const removeOnePlaylistConfirmAlert = ({ playlistLabel, playlistId }) => {
        setRemoveOnePlaylistConfirmAlertState(
                <SweetAlert
                    warning
                    showCancel
                    style={{ display: "block", marginTop: "-100px" }}
                    title={`Voulez-vous supprimez ${playlistLabel} ?`}
                    confirmBtnText="Oui"
                    cancelBtnText="Annuler"
                    onConfirm={() => {
                        removePlaylist({id: playlistId, playlists});
                        hideRemoveOnePlaylistConfirmAlert();
                        notify({
                            ref: notificationAlert,
                            type: "success",
                            place: "br",
                            message: `La playlist ${playlistLabel} a été supprimée`,
                        });
                    }}
                    onCancel={() => hideRemoveOnePlaylistConfirmAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    btnSize="md"
                />
        );
    };

    const hideRemoveOnePlaylistConfirmAlert = () =>
        setRemoveOnePlaylistConfirmAlertState(null);

    return playlists.map((playlist) => (
        <tr key={playlist.id}>
            <td className="text-center">
                <FormGroup check>
                    <Label check>
                        <Input
                            checked={playlist.checked}
                            onChange={() =>
                                toggleCheckedPlaylist({ playlistId: playlist.id })
                            }
                            type="checkbox"
                        />
                        <span className="form-check-sign">
                  <span className="check" />
                </span>
                    </Label>
                </FormGroup>
            </td>
            <td>{playlist.name}</td>
            <td className="text-center">
                {
                    playlist.groups.length > 0 ?
                        playlist.groups.map(group => (
                            group.id !== networkId
                                ? (
                                    <span key={group.id} className="badge badge-default">
                      {group.name?.toUpperCase()}
                    </span>
                                ) : (
                                    <span key={group.id} className="badge badge-default">
                      {"_" + group.name?.toUpperCase()}
                  </span>
                                )
                        ))
                        : (
                            <span key={0} className="badge badge-default">
                  Aucun
                </span>
                        )
                }
            </td>
            <td className="text-center">
                {
                    playlist.endDate > 0
                        ? (
                            Moment(playlist.startDate).isSame(playlist.endDate, "day")
                                ? Moment(playlist.startDate).format("DD MMM YYYY [de] kk:mm") + " - " + Moment(playlist.endDate).format("kk:mm")
                                : Moment(playlist.startDate).format("DD MMM YYYY à kk:mm") + " - " + Moment(playlist.endDate).format("DD MMM YYYY à kk:mm")
                        )
                        : Moment(playlist.startDate).format("DD MMM YYYY à kk:mm")
                }
            </td>
            <td className="text-center">
                {
                    playlist.endDate > 0 ? Moment(playlist.startDate).to(playlist.endDate, true) : "Infinie"
                }</td>
            <td className="text-center">
                <a>
                    <Button
                        className="btn-icon btn btn-success"
                        title="Modifier"
                        color="link"
                        onClick={() => history.push({pathname: `/admin/playlist/${playlist.id}/edit`})}
                        disabled={
                            toggleCheckedAllPlaylists ||
                            checkedPlaylists().length > 1
                        }
                    >
                        <i className="tim-icons icon-pencil" />
                    </Button>
                    <Button
                        className="btn-icon btn btn-danger"
                        onClick={() =>
                            removeOnePlaylistConfirmAlert({
                                playlistId: playlist.id,
                                playlistLabel: playlist.name
                            })
                        }
                        color="link"
                        title="Supprimer définitivement"
                        disabled={
                            toggleCheckedAllPlaylists ||
                            checkedPlaylists().length > 1
                        }
                    >
                        <i className="tim-icons icon-trash-simple" />
                    </Button>
                </a>
                {removeOnePlaylistConfirmAlertState}
            </td>
        </tr>
    ))
}

export default PlaylistTable;