import {Button, Col, Row} from "reactstrap";
import VideoThumbnail from "react-video-thumbnail";
import React from "react";

function SelectedFilesList({
    loader,
    files,
    setFiles
}) {

    const handleFilesRemoveOne = ({fileId = ""}) => {
        const newFiles = files.filter(file => file.id !== fileId);
        setFiles([...newFiles]);
    }

    return (
        <>
            { loader ?
                (
                    <Col className="mb-4 mt-4 mr-4" sm={{
                        offset: 2,
                        size: 6
                    }}>
                        <div className="loading-spinner"></div>
                    </Col>
                )
                :
                (
                    files.length > 0
                        ?
                        <Col>
                            <Button className="btn btn-warning" onClick={() => setFiles([])}>Tout effacer</Button>
                            <Row>
                                {
                                    files.map((file) => {
                                        return (
                                            <Col md="12" key={file.id}>
                                                <Row className="mt-2">
                                                    <Col width="120"
                                                         height="80">
                                                        <VideoThumbnail
                                                            videoUrl={window.URL.createObjectURL(file)}
                                                            style={{backgroundColor:"blue"}}
                                                        />
                                                    </Col>


                                                    <Col md="9" className="uploadVideoText">
                                                        <Row left>
                                                            <b>{file.name}</b>
                                                        </Row>
                                                        <Row>
                                                            <p>{file.sizeReadable}</p>
                                                        </Row>
                                                    </Col>

                                                    <Col md="1">
                                                        <Button className="btn-link btn-icon btn-round" color="primary" onClick={() => handleFilesRemoveOne({fileId: file.id})}>
                                                            <i className="fa fa-times" />
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                        : null
                )
            }
        </>
    )
}

export default SelectedFilesList;