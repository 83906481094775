import React, {createRef, forwardRef, useEffect, useRef} from 'react'
import VideoPlayer from "../../assets/img/preview_video.png";
import {Col} from "reactstrap";

function VideoPreview({
      url,
      videos = [],
      files = [],
  }) {

    const videoToPreviewRef = useRef();

    useEffect(() => {
        videoToPreviewRef.current?.load();
    }, [url]);

    return (
        <>
            {url && (videos.length > 0 || files.length > 0) ? (
                <video width="100%"  height={240} controls ref={videoToPreviewRef}
                       style={{objectFit: "cover"}}        >
                    <source src={url} type="video/mp4" />
                </video>
            ) : (
                <img src={VideoPlayer} alt="" className="w-100" height={240} />
            )}
        </>
    )
}

export default VideoPreview