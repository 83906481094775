import {Button, ButtonGroup, CardHeader, CardTitle, Col, Row} from "reactstrap";
import classNames from "classnames";
import React, { useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {removeSelectedFolders} from "../../firebase/storage";

function  StorageHeaderCard({
   newScreenModalRef,
   checkedFolders,
   notificationAlertRef,
    toggleCheckedAllFolders,
    setToggleCheckedAllFolders
}) {

    const [removeAllFoldersConfirmAlertState, setRemoveAllFoldersConfirmAlertState] = useState(null);
    const removeAllFoldersConfirmAlert = () => {
        if(checkedFolders().length > 0) {
            setRemoveAllFoldersConfirmAlertState(
                <SweetAlert
                    warning
                    showCancel
                    style={{display: "block",marginTop: "-100px"}}
                    title={`Voulez-vous supprimez ${checkedFolders().length} dossiers ?`}
                    confirmBtnText="Oui"
                    cancelBtnText="Annuler"
                    onConfirm={() => {
                        removeSelectedFolders({
                            checkedFolders: checkedFolders,
                            toggleCheckedAllFolders: toggleCheckedAllFolders,
                            setToggleCheckedAllFolders: setToggleCheckedAllFolders,
                            notificationAlert: notificationAlertRef
                        });
                        hideRemoveAllFoldersConfirmAlert();
                    }}
                    onCancel={() => hideRemoveAllFoldersConfirmAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    btnSize="md"
                />
            );
        }
    }

    const hideRemoveAllFoldersConfirmAlert = () => {
        setRemoveAllFoldersConfirmAlertState(null);
    }

    return (
        <>
            <CardHeader>
            <Row className="align-items-xs-top">
                <Col className="text-left" sm="6">
                    <CardTitle tag="h4">Stockage</CardTitle>
                </Col>
                <Col sm="6">
                    <ButtonGroup
                        className="btn-group-toggle float-right"
                        data-toggle="buttons"
                    >
                        <Button
                            tag="label"
                            className={classNames("btn-simple", {
                                active: false
                            })}
                            color="info"
                            id="0"
                            size="sm"
                            onClick={removeAllFoldersConfirmAlert}
                            disabled={!(checkedFolders()?.length > 1)}
                        >
                            <input
                                className="d-none"
                                name="options"
                                type="radio"
                            />
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Supprimer
                      </span>
                            <span className="d-block d-sm-none">
                        <i className="tim-icons icon-trash-simple" />
                      </span>
                        </Button>
                        <Button
                            color="info"
                            id="2"
                            size="sm"
                            tag="label"
                            className={classNames("btn-simple", {
                                active: true
                            })}
                            onClick={() => newScreenModalRef?.current.handleOpenModal()}
                        >
                            <input
                                className="d-none"
                                name="options"
                                type="radio"
                            />
                            <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        Nouvelle vidéo
                      </span>
                            <span className="d-block d-sm-none">
                        <i className="tim-icons icon-video-66" />
                      </span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </CardHeader>
        {removeAllFoldersConfirmAlertState}
    </>
    )
}
export default StorageHeaderCard;