import React from "react";
import { Route, Switch } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import { auth, onAuthStateChanged, } from "../../firebase/init-auth.js";
// javascript plugin used to create scrollbars on windows

// core components

import routes from "routes.js";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      currentUser: null,
      isLoading: true,
    };
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/register") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  componentDidMount() {
    onAuthStateChanged(auth, (user) => {
        if (user) {     
            this.props.history.push("/admin/dashboard")
        } else {
            this.setState({isLoading: false})
        }
    });
  }


  render() {
    if(this.state.isLoading) {
      return (
        <Spinner>
          <span className="sr-only">Loading...</span>
      </Spinner>
      )
    }

    return (
      <div className="wrapper">
      <div
        className="loginPanel"
        ref="mainPanel"
        data={this.state.backgroundColor}
      >
        <Row className="center">
            <Col sm="12"><Switch>{this.getRoutes(routes)}</Switch></Col>
        </Row>

      </div>
    </div>

    );
  }
}

export default Register;