import React, {createRef, useEffect, useState} from "react";

import { auth } from "../firebase/init-auth.js";
import { database, ref, onValue, child } from "../firebase/init-database.js";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";

// reactstrap components
import {
    Row,
    Col,
} from "reactstrap";
import ScreenList from "../components/Screen/ScreenList";
import NewScreenModal from "../components/Screen/NewScreenModal";
import GroupModal from "../components/Screen/GroupModal";
import ScreenHeader from "../components/Screen/ScreenHeader";
import {addScreen, updateScreen} from "../firebase/screen";
import {addGroup, updateGroup} from "../firebase/group_firebase";
function Screens () {
    const [networkName, setNetworkName] = useState("");
    const [networkId, setNetworkId] = useState("");
    const [screenName, setScreenName] = useState("");
    const [group, setGroup] = useState("");
    const [screenGroup, setScreenGroup] = useState("");
    const [groupId, setGroupId] = useState(0);
    const [lon, setLon] = useState(0);
    const [lat, setLat] = useState(0);
    const [macAddress, setMacAddress] = useState("");
    const [screens, setScreens] = useState([]);
    const [groups, setGroups] = useState([]);
    const [playlists, setPlaylists] = useState([]);

    const notificationAlert = createRef();
    let newScreenModalRef = createRef();
    let groupModalRef = createRef();


    useEffect(() => {
        const user = auth.currentUser;
        const userRef = ref(database, '/users/' + user.uid);
        onValue(userRef, (snapshot) => {
            const netRef = ref(database, 'networks/' + snapshot.val()?.network);
            onValue(netRef, (snap) => {
                setNetworkId(snap.key);
                setNetworkName(snap.val()?.name);

            }, {
                onlyOnce: true
            })

            onValue(child(netRef, '/screens/'), (snap) => {
                const result = snap.val();
                const list = [];
                for (let key in result)
                    if (result[key].name != null)
                        list.push({
                            name: result[key].name,
                            address: key,
                            online: result[key].online,
                            video_reading: result[key].isVideoReading,
                            screenGroup: result[key].screenGroup,
                            lat: result[key].lat,
                            lon: result[key].lon
                        })
                setScreens(list);
            });

        }, {
            onlyOnce: true
        });

    }, [])

    useEffect(() => {
        const user = auth.currentUser;
        const userRef = ref(database, '/users/' + user.uid);
        onValue(userRef, (snapshot) => {
            const netRef = ref(database, 'networks/' + snapshot.val()?.network);
            onValue(netRef, () => {
                const playlists = [];
                const playlistsRef = child(netRef, "/playlist/");

                onValue(playlistsRef, (snap) => {
                    const result = snap.val();
                    for (let key in result) {
                        playlists.push({
                            id: key,
                            name: result[key]?.title,
                            groups: result[key]?.groupsRef,
                            videos: result[key]?.videos,
                            startDate: result[key]?.startDate,
                            endDate: result[key]?.endDate,
                            checked: false
                        });
                    }
                    setPlaylists(playlists);
                })

                onValue(child(netRef, '/groups/'), (snap) => {
                    const result = snap.val();
                    const groups = [];
                    const messages = [];
                    for (let key in result) {
                        let nbScreens = 0;
                        screens.forEach(screen => {
                            if (screen.screenGroup === key) nbScreens++;
                        });

                        const messagesRef = child(netRef, '/groups/' + key + "/messages/");
                        onValue(messagesRef, (snap) => {
                            const m = snap.val();
                            for (let messageKey in m) {
                                messages.push({
                                    id: messageKey,
                                    title: m[messageKey].title,
                                    content: m[messageKey].message,
                                    state: m[messageKey].state,
                                    groupId: key
                                });
                            }
                        }, {onlyOnce: true});

                        groups.push({
                            id: key,
                            name: result[key].name,
                            messages: [],
                            screens_nb: nbScreens,
                        });

                    }

                    groups.forEach(group => {
                        messages.forEach(message => {
                            if (group.id === message.groupId) {
                                group.messages.push(message);
                            }
                        })
                    })
                    setGroups(groups);
                });

            }, {
                onlyOnce: true
            })
        })


    }, [screens.length])

    return (
        <div className="content">
            <div className="react-notification-alert-container">
                <NotificationAlert ref={notificationAlert} />
            </div>
            <Row>
                <ScreenHeader  screenModalRef={newScreenModalRef} groupModalRef={groupModalRef}/>
            </Row>
            <Row>
                <ScreenList
                    screenModalRef={newScreenModalRef}
                    screens={screens} groups={groups}
                    playlists={playlists}
                />
            </Row>
            <div>
                <Col md="3"></Col>
                <Col md="5">
                    <NewScreenModal
                        notificationAlertRef={notificationAlert}
                        ref={newScreenModalRef}
                        macAddress={macAddress} setMacAddress={macAddress => setMacAddress(macAddress)}
                        lat={lat} setLat={lat => setLat(lat)}
                        lon={lon} setLon={lon => setLon(lon)}
                        screenName={screenName} setScreenName={screenName => setScreenName(screenName)}
                        screenGroup={screenGroup} setScreenGroup={screenGroup => setScreenGroup(screenGroup)}
                        screens={screens}
                        groups={groups}
                        updateScreen={(screenId) => updateScreen({
                            screenName,
                            lat,
                            lon,
                            screenGroup,
                            networkId,
                            networkName,
                            screenId,
                        })}
                        addScreen={() => addScreen(
                            {
                                screenName,
                                macAddress,
                                lat,
                                lon,
                                screenGroup,
                                networkId,
                                networkName,
                            }
                        )}
                    />
                </Col>
                <Col md="3"></Col>
            </div>
            <div>
                <Col md="3"></Col>
                <Col md="5">
                    <GroupModal
                        notificationAlertRef={notificationAlert}
                        ref={groupModalRef}
                        groups={groups} group={group} groupId={groupId}
                        networkId={networkId} setGroup={group => setGroup(group)}
                        setGroupId={groupId => setGroupId(groupId)}
                        addGroup={() => addGroup({group})}
                        updateGroup={(groupId) => updateGroup({
                            id: groupId,
                            group,
                        })}
                    />
                </Col>
                <Col md="3"></Col>
            </div>
        </div>
    );
}

export default Screens;
