import React, {createRef, useState} from "react";
import {Button, Card, CardBody, CardFooter, Col, FormGroup, Form, Input, Row} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import { auth, signInWithEmailAndPassword, onAuthStateChanged, browserSessionPersistence, setPersistence } from "../firebase/init-auth.js";
import logo from "../assets/img/tv.svg";
import { notify } from "utils/notify.jsx";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import {Link, useHistory} from "react-router-dom";

function Login () {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const notificationAlert = createRef();
  const history = useHistory();

  const validateForm = () => {
    return (email && password) ? email.length > 0 && password.length > 8 : false;
  }

  const submit = e => {
    e.preventDefault();
    setPersistence(auth, browserSessionPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password)
      .then( ()=>{
        onAuthStateChanged(auth, (user) => {
          if (user) {     
              history.push("/admin/dashboard");
          }
        });
            
      })
      .catch(() => {
        notify({ref: notificationAlert, type: "danger", place: "tc", message: "Email ou mot de passe incorrect"});
      })
    });
  }

  return (
      <div>
        <div className="react-notification-alert-container">
            <NotificationAlert ref={notificationAlert} />
        </div>
        <Row>
            <Col xs={{size: "11", offset: "1"}} sm={{size: "10", offset: "1"}} lg={{size: "4", offset: "2"}} xl={{size: "4", offset: "2"}}>
              <div className="login-left-panel">
                  <div className="login-left-panel-title">
                        <img src={logo} alt="" />
                  </div>
                  <div className="login-left-panel-subtitle">
                    <h3>Bienvenue sur Kioo</h3>
                  </div>
                  <div className="login-left-panel-citation">
                    <p>Encouragez l’innovation. Le changement est notre force vitale,</p>
                    <p>la stagnation est notre glas. ~ David M. Ogilvy</p>
                  </div>
                </div>
            </Col>
            <Col xs={{size: "11", offset: "1"}} sm={{size: "10", offset: "1"}} lg="4" xl="3">
              <Card className="card-login" >
                <CardHeader className="card-header">
                    <h1 className="">Connexion</h1>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={submit}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Adresse mail</label>
                          <Input
                              id="email"
                              autoFocus
                              placeholder="Email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Mot de passe</label>
                          <Input
                              id="password"
                              placeholder="password"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}

                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button type="submit" color="primary" disabled={!validateForm()}>
                      Se connecter
                    </Button>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col>
                      <div className="button-container">
                        <Link className="" to="/register">
                          Pas de compte?
                        </Link>
                      </div>
                    </Col>
                    <Col>
                      <div className="button-container">
                        <a
                              href="https://www.initialde.com/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                            www.initialde.com
                        </a>
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
        </Row>
      </div>
  );

}

export default Login;
