import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import { Router } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import { createBrowserHistory } from "history";
import "assets/scss/black-dashboard-react.scss";
import "assets/css/black-dashboard-react.css";
import "assets/css/nucleo-icons.css";

const hist = createBrowserHistory();

ReactDOM.render(

  <HttpsRedirect>
    <Router history={hist}>
        <App />
    </Router>
  </HttpsRedirect>
  ,

  document.getElementById("root")
);
