// noinspection JSCheckFunctionSignatures

import React, {createRef, useEffect, useState} from "react";

// reactstrap components
import {Button, Card, CardBody, Col, Row,} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import {child, database, onValue, ref,} from "../firebase/init-database.js";
import {auth} from "../firebase/init-auth.js";
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import "moment/locale/fr";
import VideoPreview from "components/Playlist/VideoPreview.jsx";
import {Link, useHistory, useParams} from "react-router-dom";
import SelectedVideoPreview from "../components/Playlist/SelectedVideoPreview";
import {addPlaylist, getFolders, updatePlaylist} from "../firebase/playlist";
import InputsForm from "../components/Playlist/InputsForm";
import {defaultDays, isStartBeforeEndDate} from "../utils/date";
import Loader from "../components/Loader";
import {notify} from "../utils/notify";
import ButtonLoader from "../components/ButtonLoader";

function EditPlaylist() {

  const [days, setDays] = useState([...defaultDays])
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedVideoToPreview, setSelectedVideoToPreview] = useState("");
  const [groups, setGroups] = useState([{
    id: "0",
    name: "Tous les groupes",
  }]);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [networkId, setNetworkId] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [videos, setVideos] = useState([]);
  const [files, setFiles] = useState([]);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [videosFolders, setVideosFolders] = useState([{
    label: "",
    value: "",
    checked: false,
    children: []
  }]);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistId, setPlaylistId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [action, setAction] = useState("add");
  const [loading, setLoading] = useState(false);

  const notificationAlertRef = createRef();

  let selectTreeFolderRef = createRef();

  const history = useHistory();

  const validateByLength = (input) => input.length > 0;
  const validateNumber = (input) => input > 0;
  const validateDays = () => days.some(day => day.selected); // At least one selected day
  const validatePlaylistForm = () => {
    const isStartBeforeEnd = isStartBeforeEndDate({startDate: startDate, endDate: endDate});
    return (
        validateByLength(playlistName)  &&
        (validateByLength(videos )|| validateByLength(files)) &&
        validateNumber(startDate) &&
        (validateNumber(endDate) ?  isStartBeforeEnd : true) &&
        validateDays()
    );
  }

  const hydrateUpdatePlaylistForm = (playlist = {}) =>{
    const groups = playlist?.groupsRef?.filter(group => group.id !== networkId);
    setPlaylistId(playlist.key);
    setSelectedGroups(groups);
    setStartDate(Moment(playlist.startDate));
    setEndDate(playlist.endDate > 0 ? Moment(playlist.endDate) : 0);
    setPlaylistName(playlist.title);
    setDays(playlist.days);
  }

  const initAddPlaylistForm = () => {
    handleResetGroupInput();
    setPlaylistName("");
    setStartDate(Moment.now());
    setEndDate(0);
    setSelectedGroups([]);
    setDays([...defaultDays]);
    handleResetVideosAndFiles();
  }

  const handleResetGroupInput = (ref) =>{
    ref?.current?.resetSelectedValues();
  }

  const handleResetVideosAndFiles = () => {
    setVideos([]);
    setFiles([]);
  }

  const setSelectTreeFolderRef = (ref) => {
    selectTreeFolderRef = ref;
  }

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    if(action === 'update') {
      updatePlaylist({
        videos, files, startDate, endDate, days, playlistId,
        playlistName, networkName, networkId, selectedGroups, setIsVideoUploading, history
      })
      notify({
        ref: notificationAlertRef,
        type: "success",
        place: "br",
        message: `La playlist ${playlistName} a été modifiée`
      });
      setLoading(false);
    } else {
      addPlaylist({
        videos, files, startDate, endDate, days,
        playlistName, networkName, networkId, selectedGroups,
        initAddPlaylistForm, setIsVideoUploading, history
      })
      notify({ ref: notificationAlertRef, type: "success", place: "br", message: `La playlist ${playlistName} a été créée` });
      setLoading(false);
    }
  }

  let { id } = useParams();

  useEffect(() => {
    const user = auth.currentUser;
    const usersRef = ref(database, "users/" + user.uid);
    onValue(usersRef, (snapshot) => {
      const netRef = ref(database, "networks/" + snapshot.val()?.network);
      const groupsRef = child(netRef, "/groups/");
      onValue(netRef, (snap) => {
        const name = snap.val()?.name;
        const netId = snap.key
        setNetworkName(name);
        setNetworkId(netId);
        onValue(groupsRef, (snap) => {
          const result = snap.val();
          const list = [];

          for (let key in result) {
            if (key !== netId)
              list.push({
                id: key,
                name: result[key]?.name,
              });
          }
          setGroups(list);
        });
      });
    });

    getFolders((folders) => setVideosFolders(folders));
  }, []);

  useEffect(() => {
    const playlistID = id;
    if(playlistID) {
      setAction("update");
      const user = auth.currentUser;
      const usersRef = ref(database, "users/" + user.uid);

      onValue(usersRef, (snapshot) => {
        const playlistRef = ref(database, 'networks/' + snapshot.val()?.network + '/playlist/' + playlistID);

        onValue(playlistRef, (snapshot) => {
        const playlist = snapshot.val();

        playlist?.videos.forEach(playlistVideo => {

          videosFolders.forEach(folder => {

            const folderVideos = folder?.children;

            if( folderVideos.length> 0) {
              const equalVideo = folderVideos.filter(video => video.value === playlistVideo.videoUrl)[0];

              if(equalVideo) {
                let videoFolder = selectTreeFolderRef.current?.getVideoParent(equalVideo);
                selectTreeFolderRef.current?.toggleCheckedVideo(videoFolder, equalVideo, true);
                selectTreeFolderRef.current?.handleSetVideo(equalVideo);

                if(selectTreeFolderRef.current?.allFolderVideosSelected(videoFolder))
                  selectTreeFolderRef.current?.toggleCheckedFolder(videoFolder, true);
              }
            }
          })
        })
        hydrateUpdatePlaylistForm({...playlist, key: snapshot.key});
      })
      });
    }

  }, [videosFolders.length]);

  if(isVideoUploading)
    return <Loader />

  return (
      <div className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <div className="mb-2">
          <Link className="btn-link" to="/admin/playlists">
            <i className="fa fa-arrow-left" /> Playlist
          </Link>
        </div>
        <Card>
          <CardBody className="pb-2">
            <Col md="12">
              <Row>
                <InputsForm
                    handleResetGroupInput={handleResetGroupInput}
                    groups={groups} setSelectedGroups={(group) => setSelectedGroups(group)}
                    selectedGroups={selectedGroups}
                    startDate={startDate} endDate={endDate}
                    setStartDate={(date) => setStartDate(date.valueOf())} setEndDate={(date) => setEndDate(date.valueOf())}
                    days={days} setDays={(days) => setDays(days)}
                    playlistName={playlistName} setPlaylistName={(playlistName) => setPlaylistName(playlistName)}
                />
                <Col md="4" className="text-center w-100">
                  <VideoPreview
                      url={selectedVideoToPreview}
                      videos={videos}
                      files={files}
                  />
                </Col>
                <Col md="12" className="mt-4">
                  <SelectedVideoPreview
                      videos={videos}
                      setVideos={(videos) => setVideos(videos)}
                      files={files}
                      videosFolders={videosFolders}
                      setVideosFolders={(videosFolders) => setVideosFolders(videosFolders)}
                      setFiles={(files) => setFiles(files)}
                      setSelectedVideoToPreview={(videoToPrev) => setSelectedVideoToPreview(videoToPrev)}
                      loader={loader}
                      setLoader={(loader) => setLoader(loader)}
                      handleResetVideosAndFiles={() => handleResetVideosAndFiles()}
                      setSelectTreeFoldersRef={(ref) => setSelectTreeFolderRef(ref)}
                  />
                </Col>
                <Col>
                  <Row  className="justify-content-center justify-content-md-end">
                    <Col  md={4}>
                      <ButtonLoader  className="btn btn-primary text-center w-100"
                               onClick={e => submit(e)}
                               disabled={!(validatePlaylistForm())}
                               text={action === "add" ? "Enregistrer" : "Modifier"}
                               loading={loading}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </div>
  );
}

export default EditPlaylist;
