import {Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import React, {forwardRef, useImperativeHandle, useState} from "react";
import GroupRow from "./GroupRow";
import SweetAlert from "react-bootstrap-sweetalert";
import {removeGroup} from "../../firebase/group_firebase";
import {notify} from "../../utils/notify";

const GroupModal = forwardRef (({
    notificationAlertRef,
    networkId,
    groups,
    group,
    groupId,
    setGroup,
    setGroupId,
    addGroup,
    updateGroup,
    }, ref) => {
    const [showModal, setShowModal]= useState(false);
    const [groupAction, setGroupAction] = useState("add");
    const [removeOneGroupConfirmAlertState, setRemoveOneGroupConfirmAlertState] = useState(null);

    useImperativeHandle(ref, () => {
        return {
            handleOpenModal() {
                setShowModal(true);
            }
        };
    }, []);

    const removeOneGroupConfirmAlert = ({groupId, groupLabel}) => {
        setRemoveOneGroupConfirmAlertState(
            <SweetAlert
                warning
                showCancel
                style={{display: "block", marginTop: "-100px"}}
                className="text-muted sweetGroupConfirm"
                title={`Voulez-vous supprimez ${groupLabel} ?`}
                confirmBtnText="Oui"
                cancelBtnText="Annuler"
                onConfirm={() => {
                    removeGroup({id: groupId})
                    setGroupAction("add");
                    setGroup("");
                    hideRemoveOneGroupConfirmAlert();
                    notify({ref: notificationAlertRef, type: "success", place: "br", message: `Le groupe ${groupLabel} a été supprimé`});
                }}
                onCancel={hideRemoveOneGroupConfirmAlert}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                btnSize="md"
            />
        )
    }

    const hideRemoveOneGroupConfirmAlert = () => {
        setRemoveOneGroupConfirmAlertState(null);
    }

    return (
        <>
            <Modal size="lg" isOpen={showModal} toggle={() => {
                setShowModal(!showModal);
                setGroupAction("add");
            }} className="modal modal-black modal-content">
                <ModalHeader className="modal-header" toggle={() => {
                    setShowModal(!showModal);
                }}>
                    <div>
                        <div><h4 className="modal-title">Groupes</h4></div>
                    </div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Col md="12">
                        <Row>
                            <Table className="tablesorter">
                                <tbody>
                                    <GroupRow  groups={groups} networkId={networkId} setGroupId={setGroupId}
                                               setGroup={setGroup} removeOneGroupConfirmAlert={removeOneGroupConfirmAlert}
                                               setGroupAction={action => setGroupAction(action)}
                                    />
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                    <Footer
                        notificationAlertRef={notificationAlertRef}
                        setGroupAction={setGroupAction}
                        group={group}
                        groupAction={groupAction}
                        groupId={groupId}
                        setGroup={setGroup}
                        addGroup={addGroup}
                        updateGroup={updateGroup}
                        setGroupId={setGroupId}
                    />
                </ModalBody>
            </Modal>
            {removeOneGroupConfirmAlertState}
        </>
    )
});

function Footer({
    group,
    setGroup,
    groupAction,
    notificationAlertRef,
    addGroup,
    updateGroup,
    setGroupId,
    setGroupAction,
    groupId,
                     }) {
    return (
        <ModalFooter>
            <Row>
                <Col md="auto">
                    <FormGroup>
                        <Input
                            id="group"
                            placeholder="Nom du groupe"
                            type="text"
                            value ={group}
                            onChange={(e) => setGroup(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    {
                        groupAction === "add" ? (
                                <button className="btn btn-info" onClick={() => {
                                    addGroup()
                                    setGroup("")
                                    notify({ref: notificationAlertRef, type: "success", place: "br", message: `Le groupe ${group} a été ajouté`});

                                }} disabled={!group || group === " "}>Ajouter</button>
                            )
                            : (
                                <Row>
                                    <Col>
                                        <button className="btn btn-default" onClick={() => {
                                            setGroup("")
                                            setGroupId(0)
                                            setGroupAction("add")
                                        }}>Annuler</button>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-info" onClick={() => {
                                            updateGroup(groupId);
                                            setGroup("")
                                            setGroupId(0)
                                            notify({ref: notificationAlertRef, type: "success", place: "br", message: `Le groupe ${group} a été modifié`});
                                        }} disabled={!group || group === " "}>Modifier</button>
                                    </Col>
                                </Row>
                            )
                    }
                </Col>
            </Row>
        </ModalFooter>
    )
}

export default GroupModal;