import {auth} from "./init-auth";
import {database, onValue, ref, remove, set} from "./init-database";
import {addDefaultGroup} from "./group_firebase";

export const addScreen = ({
    screenName,
    macAddress,
    screenGroup,
    lon,
    lat,
    networkId,
    networkName,
                          }) =>{
    const user = auth.currentUser;

    const usersRef = ref(database, 'users/' + user.uid);
    onValue(usersRef,
        (snapshot) => {
            const networksRef =  ref(database,'networks/' + snapshot.val().network+'/screens/' + macAddress.toUpperCase());
            if(!screenGroup) {
                addDefaultGroup({
                    networkId,
                    networkName
                }, () => null);
            }

            set(networksRef,{
                name: screenName,
                screenGroup: screenGroup ? screenGroup : networkId,
                lon: lon,
                lat: lat
            })
        },
        {
            onlyOnce: true
        })


}

export const updateScreen = ({
     screenName,
     screenGroup,
     lon,
     lat,
     networkId,
     networkName,
     screenId,
}) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/' + user.uid);
    onValue(usersRef,
        (snapshot) => {
            const screenRef =  ref(database,'networks/'+snapshot.val().network+'/screens/'+ screenId.toUpperCase());
            if(!screenGroup) {
                addDefaultGroup({
                    networkId,
                    networkName
                }, () => null);
            }
            set(screenRef,{
                name: screenName,
                screenGroup: screenGroup ? screenGroup : networkId,
                lon: lon,
                lat: lat
            })
        },
        {
            onlyOnce: true
    })

}

export const removeScreen = ({screenId}) => {
    const user = auth.currentUser;
    const usersRef = ref(database, 'users/'+ user.uid);
    onValue(usersRef,
        (snapshot) => {
            const screenRef =  ref(database,'networks/' + snapshot.val().network + '/screens/' + screenId.toUpperCase());
            remove(screenRef);
        }, {
            onlyOnce: true
        })
}