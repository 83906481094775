import classNames from "classnames";
import {Button, Container, FormGroup, Input, Label} from "reactstrap";
import React, {useState} from "react";
import FolderVideosList from "./FolderVideosList";
import SweetAlert from "react-bootstrap-sweetalert";
import {notify} from "../../utils/notify";
import {removeFolder} from "../../firebase/storage";
import Folder from '../../assets/img/folder.png'
function StorageTable({
    folders = [],
    setFolders,
    checkedFolders,
    toggleCheckedAllFolders,
    setToggleCheckedAllFolders,
    notificationAlertRef,
}) {

    const [removeOneFolderConfirmAlertState, setRemoveOneFolderConfirmAlertState] = useState(null);
    const onClickHandler = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        if(hiddenElement)
            hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    const removeOneFolderConfirmAlert = (e) => ({folder}) => {
        e.stopPropagation();
        setRemoveOneFolderConfirmAlertState(
                <SweetAlert
                    warning
                    showCancel
                    style={{display: "block",marginTop: "-100px"}}
                    title={`Voulez-vous supprimez ${folder.label} ?`}
                    confirmBtnText="Oui"
                    cancelBtnText="Annuler"
                    onConfirm={() => {
                        removeFolder({folder})
                        hideRemoveOneFolderConfirmAlert()
                        notify({ref: notificationAlertRef, type: "success", place: "br", message: `Le dossier ${folder.label} a été supprimé`});
                    }}
                    onCancel={() => hideRemoveOneFolderConfirmAlert()}
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    btnSize="md"
                />
        );
    }

    const hideRemoveOneFolderConfirmAlert = () => {
        setRemoveOneFolderConfirmAlertState(null);
    }

    const toggleCheckedFolder = ({folderId = 0}) => {
        const newFolders = folders;
        newFolders.forEach(folder => {
            if(folder.id === folderId) {
                folder.checked = !folder.checked;
            }
        })
        setFolders([...newFolders]);
        setToggleCheckedAllFolders(checkedFolders().length === folders.length);
    }

    return (folders && folders.map(folder => (
        <>
            <tr key={folder?.id}
                onClick={onClickHandler}
                className={classNames({
                    'cursor-pointer': folder.videos?.length > 0
                })}
            >
                <th className="text-center">
                    <FormGroup check>
                        <Label check>
                            <Input
                                checked={folder?.checked}
                                onChange={() => toggleCheckedFolder({ folderId:  folder?.id})}
                                type="checkbox"
                            />
                            <span className="form-check-sign">
                    <span className="check" />
                  </span>
                        </Label>
                    </FormGroup>
                </th>
                <td>
                    <img src={Folder} className="icons-28" alt="Folder"/>
                    {folder?.label}</td>
                <td className="text-center">
                    {folder?.count}
                </td><td className="text-center">
                {folder?.size}
            </td>
                <td className="text-center">{folder?.date}</td>
                <td className="text-center">
                    <a>
                        <Button className="btn-icon btn btn-danger"
                                onClick={(e) => removeOneFolderConfirmAlert(e)({folder})}
                                color="link" title="Supprimer définitivement"
                                disabled={toggleCheckedAllFolders || checkedFolders().length > 1}
                        >
                            <i className="tim-icons icon-trash-simple" />
                        </Button>
                    </a>
                </td>
            </tr>
            {
                <tr className="collapse">
                    <td colSpan={6}>
                        <Container fluid>
                            <FolderVideosList folder={folder} notificationAlertRef={notificationAlertRef} />
                        </Container>
                    </td>
                </tr>
            }
            {removeOneFolderConfirmAlertState}
        </>
    )));
}

export default StorageTable;