const config = {
    server: {
        secret: 'kjVkuti2xAyF3JGCzSZTk0YWM5JhI9mgQW4rytXc',
        url: 'http://localhost:3333',
        port : 3333,
    },
    firebase :{
           apiKey: "AIzaSyBQr2lAjDFDTWaPrTa5osRubbnb7_iBMeQ",
           authDomain: "icontrole-6dd38.firebaseapp.com",
           databaseURL: "https://icontrole-6dd38-default-rtdb.firebaseio.com",
           projectId: "icontrole-6dd38",
           storageBucket: "icontrole-6dd38.appspot.com",
           messagingSenderId: "373946577910",
           appId: "1:373946577910:web:beeb8bd391f8c6fe6b8dd7",
           measurementId: "G-6TLJSR5WJ3"
    },

    rtmp_server: {
        rtmp: {
            port: 1935,
            chunk_size: 60000,
            gop_cache: true,
            ping: 60,
            ping_timeout: 30
        },
        http: {
            port: 8888,
            mediaroot: './server/media',
            allow_origin: '*'
        },
        trans: {
            ffmpeg: '/usr/bin/ffmpeg',
            tasks: [
                {
                    app: 'live',
                    hls: true,
                    hlsFlags: '[hls_time=2:hls_list_size=3:hls_flags=delete_segments]',
                    dash: true,
                    dashFlags: '[f=dash:window_size=3:extra_window_size=5]'
                }
            ]
        }
    }
};

module.exports = config;